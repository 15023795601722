import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import styles from "../Layout.module.css";
import { auth } from "../../services/auth";

export const MenuOptions = ({ userInfo }) => {
  return (
    <>
      {userInfo ? (
        <div className={styles.dropdownContentFix}>
          <img
            className={styles.profileDropdownAvatar}
            src={userInfo.image ? userInfo.image : "/images/no-profile.jpg"}
            alt="avatar"
          />
          <span>{userInfo.email}</span>
          <Link to={"/my-profile"}>
            <Button className={styles.btnDropdownContent} size="small">
              My profile
            </Button>
          </Link>
        </div>
      ) : null}
      <div className={styles.dropdownContentFix}>
        <Button
          className={styles.btnDropdownLogout}
          onClick={() => auth.logout()}
          size="small"
        >
          Sign Out
        </Button>
      </div>
    </>
  );
};
