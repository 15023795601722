import { Tooltip } from 'antd';
import React from 'react';
// import styles from '../ActivityLog.module.css';

export const CreativesStatusCol = ({ creatives }) => {

  const creativesArray = creatives.split(',');
  const creativesArrayMore = [...creativesArray];
  if (creativesArray.length > 2) {
    creativesArray.length === 3 ? creativesArrayMore.reverse().splice(1) : creativesArrayMore.reverse().splice(2);
  }
  return (
    <div>
      {(creativesArray.length === 1) && (creativesArray[0] === '') &&
        <span>
          -
        </span>
      }
      {(creativesArray.length <= 2) && (creativesArray[0] !== '') &&
        <span>
          {creativesArray.join(', ')}
        </span>
      }
      {(creativesArray.length > 2) &&
        <span>
          {creativesArray.reverse().splice(creativesArray.length - 2).reverse().join(', ')},
          <Tooltip title={creativesArrayMore.reverse().join(', ')}>
            <span style={{ color: "#989898" }}> more</span>
          </Tooltip>
        </span>
      }
    </div>
  );
};
