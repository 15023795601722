import React from 'react';
import styles from '../Preview.module.css';

export const renderCountries = (countries, countriesAll) => {
  const parsedCountries = countriesAll
    .filter((country) => countries.items.includes(country.value))
    .map((country) => (
      <span key={country.value} className={styles.targetingInfoValue}>
        {country.label}
      </span>
    ));
  return (
    <div>
      <div className={styles.targetingInfoType}>
        {countries.op === "in" ? "Included" : "Excluded"}
      </div>
      {parsedCountries}
    </div>
  );
};

export const renderOS = (targetedOs, osAll) => {
  const parsedOs = osAll
    .filter((os) => targetedOs.some((target) => Number(target.id) === Number(os.id)))
    .map((item) => (
      <span key={item.id} className={styles.targetingInfoValue}>
        {item.name}
      </span>
    ));
  return (
    <div>
      {osAll.length === parsedOs.length ? (
        <span className={styles.targetingInfoType}>All Operating Systems</span>
      ) : (
        <>
          <div className={styles.targetingInfoType}>
            Included
          </div>
          {parsedOs}
        </>
      )}
    </div>
  );
};

export const renderBrowsers = (targetBrowsers, browsersAll) => {
  const parsedBrowsers = browsersAll
    .filter((browser) => targetBrowsers.some((target) => Number(target.id) === Number(browser.id)))
    .map((item) => (
      <span key={item.id} className={styles.targetingInfoValue}>
        {item.name}
      </span>
    ));
  return (
    <div>
      {browsersAll.length === parsedBrowsers.length ? (
        <span className={styles.targetingInfoType}>All Browsers</span>
      ) : (
        <>
          <div className={styles.targetingInfoType}>
            Included
          </div>
          {parsedBrowsers}
        </>
      )}
    </div>
  );
};

export const renderDays = (targetedDays) => {
  return (
    <div>
      {targetedDays.items.length === 7 ? (
        <span className={styles.targetingInfoType}>Every Day</span>
      ) : (
        <div>
          <div className={styles.targetingInfoType}>
            {targetedDays.op === "in" ? "Included" : "Excluded"}
          </div>
          <span className={targetedDays.items.includes('monday') ? styles.statusActiveSpan : styles.statusInactiveSpan}>MON</span>
          <span className={targetedDays.items.includes('tuesday') ? styles.statusActiveSpan : styles.statusInactiveSpan}>TUE</span>
          <span className={targetedDays.items.includes('wednesday') ? styles.statusActiveSpan : styles.statusInactiveSpan}>WED</span>
          <span className={targetedDays.items.includes('thursday') ? styles.statusActiveSpan : styles.statusInactiveSpan}>THU</span>
          <span className={targetedDays.items.includes('friday') ? styles.statusActiveSpan : styles.statusInactiveSpan}>FRI</span>
          <span className={targetedDays.items.includes('saturday') ? styles.statusActiveSpan : styles.statusInactiveSpan}>SAT</span>
          <span className={targetedDays.items.includes('sunday') ? styles.statusActiveSpan : styles.statusInactiveSpan}>SUN</span>
        </div>
      )}
    </div>
  );
};

export const renderHours = (targetedHours) => {
  const hours = {
    0: '00:00-01:00',
    1: '01:00-02:00',
    2: '02:00-03:00',
    3: '03:00-04:00',
    4: '04:00-05:00',
    5: '05:00-06:00',
    6: '06:00-07:00',
    7: '07:00-08:00',
    8: '08:00-09:00',
    9: '09:00-10:00',
    10: '10:00-11:00',
    11: '11:00-12:00',
    12: '12:00-13:00',
    13: '13:00-14:00',
    14: '14:00-15:00',
    15: '15:00-16:00',
    16: '16:00-17:00',
    17: '17:00-18:00',
    18: '18:00-19:00',
    19: '19:00-20:00',
    20: '20:00-21:00',
    21: '21:00-22:00',
    22: '22:00-23:00',
    23: '23:00-00:00'
  };
  const parseHours = () => {
    return targetedHours.items.map((hour) => (
      <span key={hour} className={styles.targetingInfoValue}>
        {hours[Number(hour)]}
      </span>
    ));
  };
  return (
    <div>
      {targetedHours.items.length === 1 && targetedHours.items[0] === '24' ? (
        <span className={styles.targetingInfoType}>24 hours a Day</span>
      ) : (
        <>
          <div className={styles.targetingInfoType} >
            {targetedHours.op === "in" ? "Included" : "Excluded"}
          </div>
          {parseHours()}
        </>
      )}
    </div>
  );
};

export const renderIpIsp = (targetedIpIsp) => {
  const parseIps = () => {
    return targetedIpIsp.items.map((ip) => (
      <span key={ip} className={styles.targetingInfoValue}>
        {ip}
      </span>
    ));
  };
  return (
    <div>
      <div className={styles.targetingInfoType} >
        {targetedIpIsp.op === "in" ? "Included" : "Excluded"}
      </div>
      {parseIps()}
    </div>
  );
};

export const renderDeviceTypes = (targetedDeviceTypes, typesAll) => {
  const parseDeviceTypes = () => {
    return targetedDeviceTypes.items.map((type) => (
      <span key={type} className={styles.targetingInfoValue}>
        {type}
      </span>
    ));
  };
  return (
    <div>
      {targetedDeviceTypes.items.length === typesAll.length ? (
        <span className={styles.targetingInfoType}>All Types</span>
      ) : (
        <>
          <div className={styles.targetingInfoType}>
            {targetedDeviceTypes.op === "in" ? "Included" : "Excluded"}
          </div>
          {parseDeviceTypes()}
        </>
      )}
    </div>
  );
};

export const renderDeviceManufacturers = (targetedDeviceManufacturers, manufacturersAll) => {
  const parseDeviceManufacturers = manufacturersAll
    .filter((manufacturer) => targetedDeviceManufacturers.items.includes(manufacturer.value))
    .map((item) => (
      <span key={item.value} className={styles.targetingInfoValue}>
        {item.label}
      </span>
    ));

  return (
    <div>
      {targetedDeviceManufacturers.items.length === manufacturersAll.length ? (
        <span className={styles.targetingInfoType}>All Manufacturers</span>
      ) : (
        <>
          <div className={styles.targetingInfoType}>
            {targetedDeviceManufacturers.op === "in" ? "Included" : "Excluded"}
          </div>
          {parseDeviceManufacturers}
        </>
      )}
    </div>
  );
};

export const renderCategories = (targetedCategories, categoriesAll) => {
  const categoriesParsedAll = targetedCategories.items.split("|");
  const parseTargetedCategories = categoriesAll
    .filter((category) => categoriesParsedAll.includes(category.key))
    .map((item) => (
      <span key={item.name} className={styles.targetingInfoValue}>
        {item.name}
      </span>
    ));

  return (
    <div>
      <div className={styles.targetingInfoType}>
        {targetedCategories.op === "in" ? "Included" : "Excluded"}
      </div>
      {parseTargetedCategories}
    </div>
  );
};

export const renderSubIDPlacements = (targetedSubID) => {
  const parseSubIDs = () => {
    return targetedSubID.items.map((subId, id) => (
      <span key={id} className={styles.targetingInfoValue}>
        {subId.replace(/\.\*$/, '')}
      </span>
    ));
  };
  return (
    <div>
      <div className={styles.targetingInfoType}>
        {targetedSubID.op === "in" ? "Included" : "Excluded"}
      </div>
      {parseSubIDs()}
    </div>
  );
};

const agesAll = [
  { label: "0-3 days: Newly harvested", value: "1" },
  { label: "0-3 days: Newly harvested", value: "11" },
  { label: "3-10 days: Vigorous", value: "2" },
  { label: "10-20 days: Moderate", value: "3" },
  { label: "20-35 days: Solid", value: "4" },
  { label: "35-50 days: Satisfactory", value: "5" },
  { label: "50+ days: Fair", value: "6" },
];

export const renderUserAge = (targetedUserAge) => {
  const parseUserAge = agesAll
    .filter((age) => targetedUserAge.items.includes(age.value))
    .map((item) => (
      <span key={item.value} className={styles.targetingInfoValue}>
        {item.label}
      </span>
    ));
  return (
    <div>{parseUserAge}</div>
  );
};
