import React from 'react';
import { Select } from 'antd';
const { Option } = Select;

export const generateCampagins = (arr) => {
  return arr.map((campaign) => (
    <Option key={campaign.id} value={campaign.id}>
      {campaign.name}
    </Option>
  ));
};

export const generatePartners = (arr) => {
  return arr.map(option => (
    <Option key={option.id} value={option.id}>
      {option.partner_name}
    </Option>
  ));
};