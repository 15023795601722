import React from "react";

import { Button, Space, Tooltip } from "antd";
import { EyeOutlined, EditOutlined, CopyOutlined, DeleteOutlined } from "@ant-design/icons";

export const ActionsTableCol = ({
  id,
  action,
  setNotificationId,
  setDetailsVisible,
  setActionType,
  setActionModalVisible,
  setDeleteVisible
}) => {

  const openDetailsModal = () => {
    setDetailsVisible(true)
    setNotificationId(id)
  }

  const openActionModal = (type) => {
    setActionModalVisible(true)
    setNotificationId(id)
    setActionType(type)
  }

  const openDeleteModal = () => {
    setDeleteVisible(true)
    setNotificationId(id)
  }

  return <>
    <Space>
      <>
        <Tooltip title="See details">
          <Button onClick={openDetailsModal} style={{ color: "#737373" }} icon={<EyeOutlined />} />
        </Tooltip>
        <Tooltip title="Edit">
          <Button
            onClick={() => openActionModal("edit")}
            disabled={action === 'Deleted'}
            style={{ color: "#737373" }}
            icon={<EditOutlined />}
          />
        </Tooltip>
        <Tooltip title="Clone">
          <Button
            onClick={() => openActionModal("clone")}
            style={{ color: "#737373" }}
            icon={<CopyOutlined />}
          />
        </Tooltip>
        <Tooltip title="Delete">
          <Button
            onClick={openDeleteModal}
            disabled={action === 'Deleted'}
            style={{ color: "#737373" }}
            icon={<DeleteOutlined />}
          />
        </Tooltip>
      </>
    </Space>
  </>
};
