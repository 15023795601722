import React from "react";

import { Button, Space, Tooltip } from "antd";
import { EyeOutlined } from "@ant-design/icons";

export const ActionsTableCol = ({ id, setPreviewVisible, setCampaignId }) => {

  const openPreview = () => {
    setPreviewVisible(true)
    setCampaignId(id)
  }

  return <>
    <Space>
      <>
        <Tooltip title="Preview">
          <Button onClick={openPreview} style={{ color: "#737373" }} icon={<EyeOutlined />} />
        </Tooltip>
      </>
    </Space>
  </>
};
