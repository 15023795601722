import React from "react"
import styles from "../Approval.module.css";
import { Checkbox, Button } from "antd";

export const CreativeHeadline = ({ updateGroupAction, updateItemAction, headlinesList }) => {

    const headline = headlinesList.map((item) => (
        <li key={item.value}>
            <div className={styles.itemsValue}>
                <span>{item.value}</span>
            </div>
            <div className={styles.itemsActions}>
                <Checkbox className={styles.approveCheckBox} onClick={() => updateItemAction('titles', item.value, 'approve')} checked={item.action === 'approve'} />
                <Checkbox className={styles.rejectCheckBox} onClick={() => updateItemAction('titles', item.value, 'reject')} checked={item.action === 'reject'} />
            </div>
        </li>
    ));

    return <>
        <div className={styles.creativeDetailsContainer}>
            <div className={styles.headerTitle}>
                <p>Creative Headline</p>
                <div>
                    <Button onClick={() => updateGroupAction('titles', 'approve')} className={styles.approveGroup}>&#10003;</Button>
                    <Button onClick={() => updateGroupAction('titles', 'reject')} className={styles.rejectGroup}>&#215;</Button>
                </div>
            </div>
            <ul className={styles.itemsList}>{headline}</ul>
        </div>
    </>;
}