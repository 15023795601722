import React from "react"
import styles from "../Approval.module.css";
import { Checkbox, Button } from "antd";

export const CreativeIcon = ({ setVisible, setImageSrc, updateGroupAction, updateItemAction, iconsList }) => {

    const icons = iconsList.map((item) => (
        <li key={item.value}>
            <div className={styles.itemsValue}>
                <div className={styles.iconContainer}>
                    <img alt="icon-preview" src={item.value} />
                </div>
                <span className={styles.previewLink} onClick={() => {
                    setVisible(true)
                    setImageSrc(item.value)
                }}>Click to preview</span>
            </div>
            <div className={styles.itemsActions}>
                <Checkbox className={styles.approveCheckBox} onClick={() => updateItemAction('icons', item.value, 'approve')} checked={item.action === 'approve'} />
                <Checkbox className={styles.rejectCheckBox} onClick={() => updateItemAction('icons', item.value, 'reject')} checked={item.action === 'reject'} />
            </div>
        </li>
    ));

    return <>
        <div className={`${icons.length === 0 ? styles.creativeDetailsContainerDisabled : styles.creativeDetailsContainer}`}>
            <div className={styles.headerTitle}>
                <p>Creative Icon</p>
                <div>
                    <Button disabled={icons.length === 0} onClick={() => updateGroupAction('icons', 'approve')} className={styles.approveGroup}>&#10003;</Button>
                    <Button disabled={icons.length === 0} onClick={() => updateGroupAction('icons', 'reject')} className={styles.rejectGroup}>&#215;</Button>
                </div>
            </div>
            <ul className={styles.itemsList}>{icons}</ul>
        </div>
    </>;
}
