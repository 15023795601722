import React from 'react';
import { Select } from 'antd';
const { Option } = Select;

export const generatePartners = (arr) => {
  return arr.map(option => (
    <Option key={option.id} value={option.id}>
      {option.partner_name}
    </Option>
  ));
};

export const typeName = (item) => {
  switch (item) {
    case 'paypal':
      return 'PayPal';
    case 'wire':
      return 'Wire'
    case 'payoneer':
      return 'Payoneer'
    default:
      return item;
  }
}