import React, { useContext } from "react";
import styles from './MyProfile.module.css';
import { Tabs } from "antd";
import UserContext from "../../contexts/userContext";
import { PersonalDetails } from "./components/PersonalDetails";

const {TabPane} = Tabs;

export const MyProfile = () => {
    const { userInfo } = useContext(UserContext);
    
    return(
    <div className={styles.profileContainer}>
        <h2 className={styles.titleProfile}>My Profile</h2>
        <Tabs 
        defaultActiveKey="1"
        className={styles.tabsSection}>
            <TabPane tab="Personal Details" key="1">
                <PersonalDetails userInformations={userInfo}></PersonalDetails>
            </TabPane>
        </Tabs>
    </div>
    );
}