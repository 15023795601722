import React from "react";
import { Row, Col } from "antd";
import styles from "../Preview.module.css";

export const GeneralInfo = ({ campaignGeneral, campaignPricing, campaignLimit }) => {

    const parsePeriod = (string) => {
        switch (string) {
            case 'hour':
                return "Hourly"
            case 'day':
                return "Daily"
            case 'month':
                return "Monthly"
            default:
                return ""
        }
    }

    return <>
        <h3>Campaign General Info</h3>
        <Row>
            <Col span={10}>Campaign name</Col>
            <Col span={14}>{campaignGeneral.name}</Col>
        </Row>
        <Row>
            <Col span={10}>Status after approval</Col>
            <Col span={14}>
                <span className={`${styles.status} ${styles["status" + campaignGeneral.status]}`}>
                    {campaignGeneral.status}
                </span>
            </Col>
        </Row>
        <Row>
            <Col span={10}>Campaign vertical</Col>
            <Col span={14}>{campaignGeneral.vertical ? campaignGeneral.vertical : "/"}</Col>
        </Row>
        <Row>
            <Col span={10}>Prelander</Col>
            <Col span={14}>{campaignGeneral.has_prelander ? "Yes" : "No"}</Col>
        </Row>
        {/* <Row>
            <Col span={10}>Start date</Col>
            <Col span={14}>
                {campaignGeneral.start_time ? moment(campaignGeneral.start_time).format("DD-MM-YYYY") + ", " + moment(campaignGeneral.start_time).format("hh:mm A") : "/"}
            </Col>
        </Row>
        <Row>
            <Col span={10}>End date</Col>
            <Col span={14}>
                {campaignGeneral.end_time ? moment(campaignGeneral.end_time).format("DD-MM-YYYY") + ", " + moment(campaignGeneral.end_time).format("hh:mm A") : "/"}
            </Col>
        </Row> */}
        <Row>
            <Col span={10}>Conversion tracker</Col>
            <Col span={14}>{campaignGeneral.conversion_tracking_type}</Col>
        </Row>
        <Row>
            <Col span={10}>Conversion value ($)</Col>
            <Col span={14}>{campaignGeneral.conversion_tracking_value ? campaignGeneral.conversion_tracking_value : "Not provided"}</Col>
        </Row>
        <Row>
            <Col span={10}>CPC</Col>
            <Col span={14}>{campaignPricing && campaignPricing.price ? "$ " + campaignPricing.price : "/"}</Col>
        </Row>
        <Row>
            <Col span={10}>Budget</Col>
            <Col span={14}>
                {campaignLimit.limit_value ? "$ " + campaignLimit.limit_value + " " + parsePeriod(campaignLimit.period) : "/"}
            </Col>
        </Row>
    </>
}
