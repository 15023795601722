import styles from "../NotificationsTable.module.css";

const advertisersList = arr => {
  const list = [];
  arr.map(item => list.push(item.name));
  return list.join(", ");
};

export const allColumns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Name",
    dataIndex: "title",
  },
  {
    title: "Description",
    dataIndex: "description",
    windth: 100,
    render: description => {
      return <div className={styles.descriptionCell}>{description}</div>;
    },
  },
  {
    title: "Icon",
    dataIndex: "icon",
    render: icon => {
      return (
        <div>
          <div className={styles.iconContainer}>
            <img src={icon.src} alt="" />
          </div>
          <span>{icon.text}</span>
        </div>
      );
    },
  },
  {
    title: "Type",
    dataIndex: "type",
  },
  {
    title: "Creator",
    dataIndex: "creator",
  },
  {
    title: "Date created",
    dataIndex: "date_created",
  },
  {
    title: "Date edited",
    dataIndex: "date_edited",
  },
  {
    title: "Advertisers and companies",
    render: ({ advertisers, all_advertisers }) => {
      if (!all_advertisers) {
        return <span>{advertisersList(advertisers)}</span>;
      } else {
        return <span>All Users</span>;
      }
    },
  },
];
