import React, {
  useState,
  useEffect,
  useMemo,
  useReducer,
  useCallback,
} from "react";
import { useFetch } from "../../utils/hooks/useFetch";
import { useDebounce } from "../../utils/hooks/useDebounce";

import { AllCampaignsFilters } from "./components/AllCampaignsFilters";
import { Table } from "./components/Table";
import { filtersReducer } from "./utils/reducers";

import { allColumns } from "./utils/columns";
import { ActionsTableCol } from "./components/ActionsTableCol";
import styles from "./Accounts.module.css";
import { accountsService } from "../../services/accounts";
import { campaignsService } from "../../services/campaigns";

export const Accounts = () => {
  const [filters, setFilters] = useState({});
  const [selectedFilters, dispatchSelectedFilters] = useReducer(
    filtersReducer,
    {}
  );
  const [initialColumns, setInitialColumns] = useState(allColumns);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [partnersList, setPartnersList] = useState([]);

  const [autocompletePartnersLoading, setAutocompletePartnersLoading] =
    useState(false);
  const [searchPartner, setSearchPartner] = useState("");
  const debouncePartner = useDebounce(searchPartner, 500);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [cashedTableData, setCashedTableData] = useState([]);

  const loadAccounts = (cancelToken) =>
    accountsService.getAccountsPaged({
      selectedFilters,
      initialColumns,
      currentPage,
      pageSize,
      cancelToken,
    });

  useEffect(() => {
    dispatchSelectedFilters({ type: "", payload: { filters } });
    // eslint-disable-next-line
  }, []);

  const [
    {
      data: { results: tableData, count: totalRows },
      isFetching,
    },
    getAccounts,
  ] = useFetch(loadAccounts);

  useEffect(() => {
    const getPartners = async (searchPartner) => {
      setAutocompletePartnersLoading(true);
      try {
        const res = await campaignsService.getPartnersAutocomplete(
          searchPartner
        );
        let newPartners = [...res.data];
        setPartnersList(newPartners);
      } catch (e) {
        console.log(e);
      } finally {
        setAutocompletePartnersLoading(false);
      }
    };
    if (tableData.length !== 0) getPartners(debouncePartner);
  }, [debouncePartner, tableData]);

  const updateCashedData = useCallback((id, data) => {
    setCashedTableData((prevData) => {
      const dataId = prevData.findIndex(
        (item) => Number(item.id) === Number(id)
      );
      const tempCashedTableData = [...prevData];
      // console.log(tempCashedTableData, dataId);
      tempCashedTableData[dataId] = { ...tempCashedTableData[dataId], ...data };
      return tempCashedTableData;
    });
  }, []);

  const actionTableColumn = useMemo(() => {
    return {
      title: "Actions",
      key: "actions",
      fixed: "right",
      align: "center",
      render: (
        {
          id,
          cns_cmp_id,
          is_active,
          temporary_stripe_block,
          permanent_stripe_block,
        },
        item
      ) => (
        <ActionsTableCol
          id={id}
          cns_cmp_id={cns_cmp_id}
          status={is_active}
          temporaryStripeBlock={temporary_stripe_block}
          permanentStripeBlock={permanent_stripe_block}
          filters={filters}
          dispatchSelectedFilters={dispatchSelectedFilters}
          updateCashedData={updateCashedData}
        />
      ),
    };
  }, [filters, updateCashedData]);

  const [tableColumns, setTableColumns] = useState([
    ...allColumns,
    actionTableColumn,
  ]);

  useEffect(() => {
    setCashedTableData(tableData);
  }, [tableData]);

  useEffect(() => {
    if (selectedFilters.filters) {
      getAccounts();
    }
  }, [currentPage, pageSize, getAccounts, selectedFilters]);

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.pageTitle}>
          <h4>All Campaigns</h4>
          <p>Check out all Advertiser platform campaigns here.</p>
        </div>
        <AllCampaignsFilters
          // campaignsList={campaignsList}
          autocompletePartnersLoading={autocompletePartnersLoading}
          setSearchPartner={setSearchPartner}
          partnersList={partnersList}
          submit={(filters) => {
            dispatchSelectedFilters({ type: "", payload: { filters } });
          }}
          filters={filters}
          setFilters={setFilters}
        />
        <Table
          tableColumns={tableColumns}
          setTableColumns={setTableColumns}
          tableData={cashedTableData}
          totalRows={totalRows}
          currentPage={currentPage}
          pageSize={pageSize}
          loading={isFetching}
          getAccounts={getAccounts}
          onPaginationChange={(currentPage, pageSize) => {
            setCurrentPage(currentPage);
            setPageSize(pageSize);
          }}
          initialColumns={initialColumns}
          setInitialColumns={setInitialColumns}
          selectedFilters={selectedFilters}
          filters={filters}
          dispatchSelectedFilters={dispatchSelectedFilters}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      </div>
    </>
  );
};
