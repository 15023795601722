import React, { useState, useEffect, useMemo, useReducer } from "react";
import { useFetch } from "../../utils/hooks/useFetch";
import { useDebounce } from "../../utils/hooks/useDebounce";

import { NotificationsFilters } from "./components/NotificationsFilters";
import { Table } from "./components/Table";
import { ActionsTableCol } from "./components/ActionsTableCol";

import { filtersReducer } from "./utils/reducers";
import { allColumns } from "./utils/columns";

import { DetailsModal } from "./components/DetailsModal";
import { ActionModal } from "./components/ActionModal";
import { DeleteModal } from "./components/DeleteModal";

import { notificationsService } from "../../services/notifications";

import styles from "./NotificationsTable.module.css";
import moment from "moment";

export const NotificationsTable = () => {
  const [filters, setFilters] = useState({});
  const [selectedFilters, dispatchSelectedFilters] = useReducer(
    filtersReducer,
    {}
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);

  const [dateCreatedRange, setDateCreatedRange] = useState({
    from: moment().subtract(7, "days"),
    to: moment(),
  });
  const [dateEditedRange, setDateEditedRange] = useState({});

  const [partnersList, setPartnersList] = useState([]);
  const [autocompletePartnersLoading, setAutocompletePartnersLoading] =
    useState(false);
  const [searchPartner, setSearchPartner] = useState("");
  const debouncePartner = useDebounce(searchPartner, 500);

  const [creatorsList, setCreatorsList] = useState([]);
  const [creatorsLoading, setCreatorsLoading] = useState(false);

  const [detailsVisible, setDetailsVisible] = useState(false);
  const [actionType, setActionType] = useState("");
  const [actionModalVisible, setActionModalVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [notificationId, setNotificationId] = useState();

  const [notificationDetails, setNotificationDetails] = useState({});
  const [loadDetails, setLoadDetails] = useState(false);

  const [isDeleteIncluded, setIsDeleteIncluded] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    const getPartners = async (searchPartner) => {
      setAutocompletePartnersLoading(true);
      try {
        const res = await notificationsService.getPartnersAutocomplete(
          searchPartner
        );
        if (res.data) {
          setPartnersList(res.data);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setAutocompletePartnersLoading(false);
      }
    };
    getPartners(debouncePartner);
  }, [debouncePartner]);

  useEffect(() => {
    const getCreators = async () => {
      setCreatorsLoading(true);
      try {
        const res = await notificationsService.getCreators();
        if (res) {
          setCreatorsList(res);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setCreatorsLoading(false);
      }
    };
    getCreators();
  }, []);

  const loadNotifications = (cancelToken) =>
    notificationsService.getNotificationsPaged({
      selectedFilters,
      isDeleteIncluded,
      currentPage,
      pageSize,
      cancelToken,
      dateCreatedRange,
    });

  useEffect(() => {
    dispatchSelectedFilters({ type: "", payload: { filters } });
    // eslint-disable-next-line
  }, []);

  const [
    {
      data: { results: notifications, count: totalRows },
      isFetching,
    },
    getNotifications,
  ] = useFetch(loadNotifications);

  const actionTableColumn = useMemo(() => {
    return {
      title: "Actions",
      key: "actions",
      fixed: "right",
      align: "center",
      render: ({ id, action }) => (
        <ActionsTableCol
          id={id}
          action={action}
          setNotificationId={setNotificationId}
          setDetailsVisible={setDetailsVisible}
          setActionType={setActionType}
          setActionModalVisible={setActionModalVisible}
          setDeleteVisible={setDeleteVisible}
        />
      ),
    };
  }, []);

  useEffect(() => {
    if (selectedFilters.filters) {
      getNotifications();
    }
  }, [currentPage, pageSize, getNotifications, selectedFilters]);

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dateCreatedRange: {
        from: dateCreatedRange.from,
        to: dateCreatedRange.to,
      },
      dateEditedRange: {
        from: dateEditedRange.from,
        to: dateEditedRange.to,
      },
    }));
  }, [dateCreatedRange, dateEditedRange]);

  useEffect(() => {
    const getDetails = async () => {
      setLoadDetails(true);
      try {
        if (notificationId) {
          const res = await notificationsService.getNotificationDetails(
            notificationId
          );
          if (res.success) {
            setNotificationDetails(res.data);
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoadDetails(false);
      }
    };
    getDetails();
  }, [actionModalVisible, notificationId]);

  return (
    <div className={styles.tableContainer}>
      <div className={styles.pageTitle}>
        <h4>All Notifications</h4>
        <p>View and manage all of your current and previous notifications.</p>
      </div>
      <NotificationsFilters
        autocompletePartnersLoading={autocompletePartnersLoading}
        setSearchPartner={setSearchPartner}
        creatorsList={creatorsList}
        creatorsLoading={creatorsLoading}
        partnersList={partnersList}
        submit={(filters) => {
          dispatchSelectedFilters({ type: "", payload: { filters } });
        }}
        filters={filters}
        setFilters={setFilters}
        dateCreatedRange={dateCreatedRange}
        setDateCreatedRange={setDateCreatedRange}
        dateEditedRange={dateEditedRange}
        setDateEditedRange={setDateEditedRange}
        isDeleteIncluded={isDeleteIncluded}
        setIsDeleteIncluded={setIsDeleteIncluded}
      />
      <Table
        tableColumns={[...allColumns, actionTableColumn]}
        notifications={notifications}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loadingNotifications={isFetching}
        filters={filters}
        dispatchSelectedFilters={dispatchSelectedFilters}
        getNotifications={getNotifications}
        onPaginationChange={(currentPage, pageSize) => {
          setCurrentPage(currentPage);
          setPageSize(pageSize);
        }}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
      <DetailsModal
        notificationId={notificationId}
        notificationDetails={notificationDetails}
        loadDetails={loadDetails}
        setDetailsVisible={setDetailsVisible}
        detailsVisible={detailsVisible}
        setActionType={setActionType}
        setActionModalVisible={setActionModalVisible}
      />
      <ActionModal
        actionType={actionType}
        notificationId={notificationId}
        notificationDetails={notificationDetails}
        loadDetails={loadDetails}
        setActionModalVisible={setActionModalVisible}
        actionModalVisible={actionModalVisible}
        filters={filters}
        dispatchSelectedFilters={dispatchSelectedFilters}
        setSelectedRowKeys={setSelectedRowKeys}
      />
      <DeleteModal
        notificationId={notificationId}
        setDeleteVisible={setDeleteVisible}
        deleteVisible={deleteVisible}
        filters={filters}
        dispatchSelectedFilters={dispatchSelectedFilters}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    </div>
  );
};
