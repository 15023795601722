import React, { useEffect, useState } from "react";
import { Drawer, Space, Spin, Row, Col, Button, Image } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { CreativeHeadline } from "./components/CreativeHeadline";
import { CreativeDescription } from "./components/CreativeDescription";
import { LandingURL } from "./components/LandingURL";
import { CreativeIcon } from "./components/CreativeIcon";
import { CreativeImage } from "./components/CreativeImage";
import moment from "moment";

import { campaignsService } from "../../../../services/campaigns";
import styles from "./Approval.module.css";
import { parseCreatives, reconstructCreatives } from "./utils";
import { openSuccessNotification, openWarningNotification, openErrorNotification } from "../../../../utils/notifications";


export const CreativesApproval = ({ campaignId, setApprovalVisible, approvalVisible }) => {
    const [loadingCreatives, setLoadingCreatives] = useState(true);
    const [initialCreatives, setInitialCreatives] = useState([]);
    const [creatives, setCreatives] = useState({ names: [], titles: [], descriptions: [], icons: [], images: [], landing_urls: [] });
    const [campaignInfo, setCampaignInfo] = useState({});
    const [loadingDetails, setLoadingDetails] = useState(true);
    const [saveLoading, setSaveLoading] = useState(false);

    const [imageSrc, setImageSrc] = useState('');
    const [visible, setVisible] = useState(false);

    const updateItemAction = (group, value, action) => {
        const parsedCreatives = { ...creatives };
        const itemId = parsedCreatives[group].findIndex((item) => item.value === value);
        parsedCreatives[group][itemId] = { ...parsedCreatives[group][itemId], action }
        setCreatives(parsedCreatives);
    }

    const updateGroupAction = (group, action) => {
        const parsedCreatives = { ...creatives };
        parsedCreatives[group].map((item) => updateItemAction(group, item.value, action))
        setCreatives(parsedCreatives);
    }

    const approveAll = () => {
        const parsedCreatives = { ...creatives };
        for (const [key, value] of Object.entries(parsedCreatives)) {
            value.forEach((element, index) => parsedCreatives[key][index] = { ...parsedCreatives[key][index], action: 'approve' })
        }
        setCreatives(parsedCreatives);
    }

    useEffect(() => {
        const getCreatives = async () => {
            setLoadingCreatives(true);
            setLoadingDetails(true);
            try {
                const res = await campaignsService.getCampaignCreatives(
                    campaignId
                );
                if (res.success) {
                    setCampaignInfo(res.data.campaign_info)
                    setInitialCreatives(res.data.creatives)
                    setCreatives(parseCreatives(res.data.creatives))
                } else {
                    setApprovalVisible(false)
                    openErrorNotification({ message: res.message });
                }
            } catch (e) {
                console.log(e);
            } finally {
                setLoadingCreatives(false);
                setLoadingDetails(false);
            }
        };
        if (approvalVisible) campaignId && getCreatives();
    }, [approvalVisible, setApprovalVisible, campaignId]);

    const handleSaveCreativeStatuses = async () => {
        const finalCreatives = reconstructCreatives(creatives, initialCreatives);

        if (!finalCreatives.every(item => item.action)) {
            openWarningNotification({ message: 'Please complete campaign review inputs.' })
            return;
        }

        setSaveLoading(true);

        try {
            const res = await campaignsService.approveCampaign(campaignId, { 'creatives': finalCreatives });
            if (res.success) {
                openSuccessNotification({ message: res.message });
                setTimeout(() => {
                    window.location.reload(false)
                }, 1500);
            } else {
                openWarningNotification({ message: res.message });
            }
        } catch (e) {
            console.log(e);
            openWarningNotification({ message: 'Something went wrong!' });
        } finally {
            setSaveLoading(false);
        }
    }

    return <>
        <Drawer
            placement="right"
            className={styles.drawerContainer}
            visible={approvalVisible}
            width="100%"
            title={
                <div>
                    <div className={styles.drawerTitle}>
                        <img alt="anouncement" src="images/bull-horn-announcer.svg" />
                        <h3>Campaign Review</h3>
                    </div>
                    <div className={styles.drawerCmpDetails}>
                        {!loadingDetails ? (
                            <>
                                <span className={`${styles.status} ${styles["status" + campaignInfo.status]}`}>
                                    {campaignInfo.status}
                                </span>
                                <p>{campaignInfo.name}; ID:{campaignInfo.id}, Created by {campaignInfo.partner_name} on {moment(campaignInfo.date_created).format("DD-MM-YYYY")} at {moment(campaignInfo.date_created).format("hh:mm A")}</p>
                            </>
                        ) : (
                            <Spin spinning={loadingDetails} />
                        )}
                    </div>

                </div>
            }
            footer={false}
            closable={false}
            extra={
                <Space>
                    <Button type="primary" size="large" ghost onClick={approveAll}>
                        Approve All Creatives
                    </Button>
                    <CloseCircleOutlined style={{ color: "#D1D1D1", fontSize: "20px", marginLeft: "1rem" }} onClick={() => setApprovalVisible(false)} />
                </Space>
            }
        >
            <div className={styles.creativesApprovalContainer}>
                <Spin spinning={loadingCreatives}>
                    <Row gutter={[24, 24]}>
                        <Col span={8}>
                            <CreativeHeadline updateGroupAction={updateGroupAction} updateItemAction={updateItemAction} headlinesList={creatives.titles} />
                            <CreativeDescription updateGroupAction={updateGroupAction} updateItemAction={updateItemAction} descriptionsList={creatives.descriptions} />
                        </Col>
                        <Col span={8}>
                            <LandingURL updateGroupAction={updateGroupAction} updateItemAction={updateItemAction} urlsList={creatives.landing_urls} />
                            <CreativeIcon setVisible={setVisible} setImageSrc={setImageSrc} updateGroupAction={updateGroupAction} updateItemAction={updateItemAction} iconsList={creatives.icons} />
                        </Col>
                        <Col span={8}>
                            <CreativeImage setVisible={setVisible} setImageSrc={setImageSrc} updateGroupAction={updateGroupAction} updateItemAction={updateItemAction} imagesList={creatives.images} />
                            <Button type="primary" size="large" onClick={handleSaveCreativeStatuses} loading={saveLoading}>
                                Save All Creative Statuses
                            </Button>
                        </Col>
                    </Row>
                </Spin>
            </div>
            <Image
                width={200}
                style={{ display: 'none' }}
                src={imageSrc}
                preview={{
                    visible,
                    src: imageSrc,
                    onVisibleChange: value => {
                        setVisible(value);
                    },
                }}
            />
        </Drawer>
    </>
}