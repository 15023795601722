import { callApi } from "./api";

const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return "";
  }
  const query = Object.keys(queryParams)
    .filter((key) => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== "";
    })
    .map((key) => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");
  return query ? (withoutQuestionMark ? query : `?${query}`) : "";
};

export const getOffsetLimitFromPagination = ({ currentPage, pageSize }) => ({
  limit: pageSize,
  offset: (currentPage - 1) * pageSize,
});

class AccountsService {
  async getAllDeviceTypes() {
    return await callApi({ url: "/api/device_types" });
  }

  async getAllDeviceManufacturers() {
    return await callApi({ url: "/api/device_manufacturers" });
  }

  async getAccountsPaged({
    currentPage,
    pageSize,
    cancelToken,
    selectedFilters,
  }) {
    const { filters } = selectedFilters;

    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      company_id: filters.company_id && filters.company_id.join(","),
      partner_id: filters.partner_id && filters.partner_id.join(","),
      status: filters.is_active,
      search: filters.search,
    });
    const response = await callApi({
      url: `api/partners/${query}`,
      cancelToken,
    });

    return { results: response.results, count: response.count };
  }

  // async getAllCampaignsPaged({
  //   currentPage,
  //   pageSize,
  //   cancelToken,
  //   selectedFilters,
  // }) {
  //   const { filters } = selectedFilters;

  //   const query = getQueryString({
  //     ...getOffsetLimitFromPagination({ currentPage, pageSize }),
  //     id: filters.campaigns && filters.campaigns.join(","),
  //     partner: filters.partner_id && filters.partner_id.join(","),
  //     status: filters.status && filters.status.join(","),
  //     type: filters.type && filters.type.join(","),
  //   });
  //   const response = await callApi({
  //     url: `api/campaigns/all/${query}`,
  //     cancelToken,
  //   });
  //   return { results: response.results, count: response.count };
  // }

  async getCampaignsAutocomplete(searchTermCmp) {
    return await callApi({
      url: `/api/campaigns/autocomplete/?search=${searchTermCmp}`,
    });
  }

  async getAllCampaignsAutocomplete(searchTermCmp) {
    return await callApi({
      url: `/api/campaigns/all/autocomplete/?search=${searchTermCmp}`,
    });
  }

  async getCampaignDetails(campaignId) {
    return await callApi({ url: `/api/campaigns/${campaignId}` });
  }

  async getCampaignCreatives(campaignId) {
    return await callApi({ url: `/api/creatives/${campaignId}` });
  }

  async getPartnersAutocomplete(searchTermPartner) {
    return await callApi({
      url: `/api/partners/autocomplete/?search=${searchTermPartner}`,
    });
  }

  async approveCampaign(id, data) {
    return await callApi({
      url: `api/campaigns/${id}/approve/`,
      method: "POST",
      data,
    });
  }

  async approveCampaignsAndCreatives(campaignIds) {
    return await callApi({
      url: "api/campaigns/approve/",
      method: "POST",
      data: { campaign_ids: campaignIds },
    });
  }

  async updateCampaign(data) {
    return await callApi({
      url: "api/campaigns/update/",
      method: "POST",
      data,
    });
  }

  async updateAccount(id, data) {
    return await callApi({
      url: `api/partners/${id}/edit/`,
      method: "POST",
      data,
    });
  }

  async login(cns_cmp_id) {
    return await callApi({
      url: `api/ssp/login/?cns_cmp_id=${cns_cmp_id}`,
      method: "GET",
    });
  }
}

export const accountsService = new AccountsService();
