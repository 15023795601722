import React from "react";
import { useNavigate } from 'react-router-dom';

import { Button, Space, Tooltip } from "antd";
import { EyeOutlined, BarChartOutlined, AuditOutlined } from "@ant-design/icons";

export const ActionsTableCol = ({ id, status, campaign_name, setPreviewVisible, setApprovalVisible, setCampaignId }) => {

  const history = useNavigate();

  const openPreview = () => {
    setCampaignId(id);
    setPreviewVisible(true);
  }

  const openApproval = () => {
    setCampaignId(id);
    setApprovalVisible(true);
  }

  return <>
    <Space>
      <>
        <Tooltip title="Preview">
          <Button onClick={openPreview} style={{ color: "#737373" }} icon={<EyeOutlined />} />
        </Tooltip>
        <Tooltip title="Stats">
          <Button onClick={() => history(`/reports?id=${id}&title=${campaign_name}`)} style={{ color: "#737373" }} icon={<BarChartOutlined />} />
        </Tooltip>
        <Tooltip title="Approve/Reject">
          <Button
            disabled={status === 'suspended' || status === 'disabled'}
            onClick={openApproval}
            style={{ color: "#737373" }}
            icon={<AuditOutlined />} />
        </Tooltip>
      </>
    </Space>
  </>
};
