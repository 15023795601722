import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Spin } from "antd";

import styles from "./App.module.css";

import { auth } from "./services/auth";
import { AppLayout } from "./layout";
import { PageNotFound } from "./pages/page-not-found";

export const App = () => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function load() {
      try {
        await auth.loadPermissions();
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
    load();
  }, []);

  return isLoading ? (
    <div className={styles.spinnerContainer}>
      <Spin size="large" />
    </div>
  ) : (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/page-not-found"
          render={(props) => {
            const { state } = props.location;
            const from = state && state.from;
            return <PageNotFound from={from} />;
          }}
        />
        <Route path="/*" element={<AppLayout />} />
      </Routes>
    </BrowserRouter>
  );
};
