import React, { useState, useMemo, useEffect } from "react";
import { Table as AntTable } from "antd";

import styles from './Nested.module.css';

import { subColumns } from "../utils/columns";
import { ActionsTableCol } from "./NestedActionsTableCol";

import { campaignsService } from "../../../services/campaigns";
import { openErrorNotification } from "../../../utils/notifications";

export const NestedTable = ({ item, filters, dispatchSelectedFilters }) => {

    const [creatives, setCreatives] = useState([])
    const [loading, setLoading] = useState(false);
    const [trigger, setTrigger] = useState();

    useEffect(() => {
        const getCreatives = async () => {
            setLoading(true);
            try {
                const res = await campaignsService.getCampaignCreatives(
                    item.id
                );
                if (res.success) {
                    setCreatives(res.data.creatives)
                } else {
                    openErrorNotification({ message: res.message });
                }
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        };
        getCreatives();
    }, [item.id, trigger]);

    const actionTableColumn = useMemo(() => {
        return {
            title: "Actions",
            key: "actions",
            fixed: "right",
            align: "center",
            render: ({ id, name, description, landing_url, title, icon, image, actions }) => (
                <ActionsTableCol
                    cmp_id={item.id}
                    id={id}
                    name={name}
                    description={description}
                    landing_url={landing_url}
                    title={title}
                    icon={icon}
                    image={image}
                    actions={actions}
                    setTrigger={setTrigger}
                    creativesLength={creatives.length}
                    filters={filters}
                    dispatchSelectedFilters={dispatchSelectedFilters}
                />
            ),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.id, creatives]);

    return (
        <>
            <p className={styles.rowsInfo}>Showing {creatives.length} creative(s)</p>
            <div className={styles.nestedTableWrapper}>
                <AntTable
                    rowKey={({ id }) => id}
                    loading={loading}
                    className={styles.nestedTableContainer}
                    columns={[...subColumns, actionTableColumn]}
                    dataSource={creatives}
                    pagination={false}
                    scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
                />
            </div>
        </>
    );
}
