import React, { useState } from "react";
import { useEffect } from "react";
import { auth } from "../services/auth";
const UserContext = React.createContext({});
export default UserContext;

export const UserContextProvider = props => {
  const [userInfo, setUserInfo] = useState({});

  const [loading, setLoading] = useState(false);

  const getUser = async () => {
    setLoading(true);
    try {
      const userObj = await auth.loadPermissions();
      setUserInfo(userObj[0]);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getUser();
  }, []);

  return (
    <UserContext.Provider
      value={{
        userInfo,
        setUserInfo,
        loading,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
