import React, { useState } from "react";
import { Button, Space, Spin, Tooltip } from "antd";
import styles from './Nested.module.css';
import { openSuccessNotification, openWarningNotification } from "../../../utils/notifications";
import { campaignsService } from "../../../services/campaigns";
import { LoadingOutlined } from '@ant-design/icons';

export const ActionsTableCol = ({
  cmp_id,
  id,
  name,
  description,
  landing_url,
  title,
  icon,
  image,
  actions,
  setTrigger,
  creativesLength,
  filters,
  dispatchSelectedFilters,
}) => {

  const [loading, setLoading] = useState(false)

  const handleUpdateCreative = async (action) => {
    const creative = {
      creatives: [{
        id: id,
        name: name,
        description: description,
        landing_url: landing_url,
        title: title,
        icon: icon,
        image: image,
        action: action,
        actions: actions
      }]
    };
    setLoading(true)
    try {
      const res = await campaignsService.approveCampaign(cmp_id, creative);
      if (res.success) {
        openSuccessNotification({ message: res.message });
      } else {
        openWarningNotification({ message: res.message });
      }
    } catch (e) {
      console.log(e);
      openWarningNotification({ message: 'Something went wrong!' });
    } finally {
      setLoading(false)
      if (creativesLength === 1 && action === 'approve') {
        dispatchSelectedFilters({ type: "", payload: { filters } });
      }
      else {
        setTrigger(id)
      }
    }
  }

  return <>
    <Space>
      <>
        {loading
          ?
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}></Spin>
          :
          <>
            <Tooltip title="Approve">
              <Button onClick={() => handleUpdateCreative('approve')} className={styles.approveAll}>&#10003;</Button>
            </Tooltip>
            <Tooltip title="Reject">
              <Button onClick={() => handleUpdateCreative('reject')} className={styles.rejectAll}>&#215;</Button>
            </Tooltip>
          </>
        }
      </>
    </Space>
  </>
};
