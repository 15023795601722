import { callApi } from "./api";

const getQueryString = (queryParams, withoutQuestionMark = false) => {
    if (!queryParams) {
        return "";
    }
    const query = Object.keys(queryParams)
        .filter(key => {
            const value = queryParams[key];
            return value !== undefined && value !== null && value !== "";
        })
        .map(key => {
            const value = queryParams[key];
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join("&");
    return query ? (withoutQuestionMark ? query : `?${query}`) : "";
};

export const getOffsetLimitFromPagination = ({ currentPage, pageSize }) => ({
    limit: pageSize,
    offset: (currentPage - 1) * pageSize,
});

class TransactionsService {
    async getTransactionsPaged({
        dateRange,
        selectedFilters,
        currentPage,
        pageSize,
        cancelToken,
    }) {
        const { filters } = selectedFilters;
        const query = getQueryString({
            ...getOffsetLimitFromPagination({ currentPage, pageSize }),
            date_from: dateRange?.from?.format("YYYY-MM-DD"),
            date_to: dateRange?.to?.format("YYYY-MM-DD"),
            user: filters.partner_id && filters.partner_id.join(","),
            transaction_status: filters.status && filters.status.join(","),
            payment_type: filters.type && filters.type.join(","),
            search: filters.search,
        });
        const response = await callApi({
            url: `/api/transactions/${query}`,
            cancelToken,
        });
        return { results: response.results, count: response.count };
    }
}

export const transactionsService = new TransactionsService();
