import React from "react"
import styles from "../Approval.module.css";
import { Checkbox, Button } from "antd";

export const CreativeDescription = ({ updateGroupAction, updateItemAction, descriptionsList }) => {

    const description = descriptionsList.map((item) => (
        <li key={item.value}>
            <div className={styles.itemsValue}>
                <span>{item.value}</span>
            </div>
            <div className={styles.itemsActions}>
                <Checkbox className={styles.approveCheckBox} onClick={() => updateItemAction('descriptions', item.value, 'approve')} checked={item.action === 'approve'} />
                <Checkbox className={styles.rejectCheckBox} onClick={() => updateItemAction('descriptions', item.value, 'reject')} checked={item.action === 'reject'} />
            </div>
        </li>
    ));

    return <>
        <div className={`${description.length === 0 ? styles.creativeDetailsContainerDisabled : styles.creativeDetailsContainer}`}>
            <div className={styles.headerTitle}>
                <p>Creative Description</p>
                <div>
                    <Button disabled={description.length === 0} onClick={() => updateGroupAction('descriptions', 'approve')} className={styles.approveGroup}>&#10003;</Button>
                    <Button disabled={description.length === 0} onClick={() => updateGroupAction('descriptions', 'reject')} className={styles.rejectGroup}>&#215;</Button>
                </div>
            </div>
            <ul className={styles.itemsList}>{description}</ul>
        </div>
    </>;
}