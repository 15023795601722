import { AllCampaigns } from "../pages/all-campaigns";
import { Dashboard } from "../pages/dashboard";
import {
  DashboardOutlined,
  PlayCircleOutlined,
  BarChartOutlined,
  CreditCardOutlined,
  UserOutlined,
  SwitcherOutlined,
  NotificationOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Reports } from "../pages/reports";
import { AccountStatements } from "../pages/account-statements";
import { MyProfile } from "../pages/my-profile";
import { ActivityLog } from "../pages/activity-log";
import { Notification } from "../pages/new-notification";
import { NotificationsTable } from "../pages/notifications-table";
import { TransactionsTable } from "../pages/transactions"
import { Accounts } from "../pages/accounts";
import { auth } from "../services/auth";

export const appRoutes = [
  {
    title: "Approval Central",
    path: "/",
    icon: <PlayCircleOutlined />,
    element: <Dashboard />,
  },
  {
    title: "All Campaigns",
    path: "/all-campaigns",
    icon: <DashboardOutlined />,
    element: <AllCampaigns />,
  },
  {
    title: "Reports",
    path: "/reports",
    icon: <BarChartOutlined />,
    element: <Reports />,
  },
  {
    title: "Activity Log",
    path: "/activity-log",
    icon: <SwitcherOutlined />,
    element: <ActivityLog />,
  },
  {
    title: "Accounts",
    path: "/accounts",
    icon: <TeamOutlined />,
    element: <Accounts />,
  },
  {
    title: "Billing",
    path: "/billing",
    icon: <CreditCardOutlined />,
    children: [
      {
        title: "Account Statements",
        path: "/account-statements",
        element: <AccountStatements />,
      },
      {
        title: "Transactions",
        path: "/transactions",
        is_supeuser: true,
        element: <TransactionsTable />,
      }
    ],
  },
  {
    title: "Notifications",
    path: "/notifications",
    icon: <NotificationOutlined />,
    children: [
      {
        title: "New Notification",
        path: "/new-notification",
        element: <Notification />,
      },
      {
        title: "All Notifications",
        path: "/all-notifications",
        element: <NotificationsTable />,
      }
    ],
  },
  {
    title: "My Profile",
    path: "/my-profile",
    icon: <UserOutlined />,
    element: <MyProfile />,
  },
];

export const getAllowedRoutes = () => {
  return appRoutes.filter(item => !item.is_supeuser ? item : auth.isSuperuser());
};
