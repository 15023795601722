import React from "react";
import styles from "./../Layout.module.css";
import { RightCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";

export const ModuleName = ({ collapsed, onCollapsed }) => {
  const renderTitle = collapsed => {
    switch (collapsed) {
      case false:
        return "Admin Dash";
      case true:
        return "AD";
      default:
        return "\xa0\xa0\xa0";
    }
  };

  return (
    <div
      className={styles.sidebarModuleDisp}
      style={{
        backgroundImage: "url(/images/rocket-sidebar.png)",
      }}
    >
      <span onClick={() => onCollapsed(!collapsed)}>
        {collapsed ? (
          <RightCircleOutlined style={{ fontSize: 30 }} />
        ) : (
          <LeftCircleOutlined style={{ fontSize: 30 }} />
        )}
      </span>
      <span>{renderTitle(collapsed)}</span>
    </div>
  );
};
