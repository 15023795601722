import React from "react"
import styles from "../Approval.module.css";
import { Tooltip, Button, Checkbox } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { openSuccessNotification } from '../../../../../utils/notifications';

export const LandingURL = ({ updateGroupAction, updateItemAction, urlsList }) => {

    const url = urlsList.map((item) => (
        <li key={item.value}>
            <div className={styles.itemsValue}>
                <span>{item.value}</span>
            </div>
            <div className={styles.itemsActions}>
                <Tooltip title="Copy full URL">
                    <CopyToClipboard text={item.value} onCopy={() => openSuccessNotification({ message: 'Successfully copied to clipboard!' })}>
                        <Button style={{ color: "#737373" }} icon={<CopyOutlined />} />
                    </CopyToClipboard>
                </Tooltip>
                <Checkbox className={styles.approveCheckBox} onClick={() => updateItemAction('landing_urls', item.value, 'approve')} checked={item.action === 'approve'} />
                <Checkbox className={styles.rejectCheckBox} onClick={() => updateItemAction('landing_urls', item.value, 'reject')} checked={item.action === 'reject'} />
            </div>
        </li>
    ));

    return <>
        <div className={styles.creativeDetailsContainer}>
            <div className={styles.headerTitle}>
                <p>Landing URL</p>
                <div>
                    <Button onClick={() => updateGroupAction('landing_urls', 'approve')} className={styles.approveGroup}>&#10003;</Button>
                    <Button onClick={() => updateGroupAction('landing_urls', 'reject')} className={styles.rejectGroup}>&#215;</Button>
                </div>
            </div>
            <ul className={styles.itemsList}>{url}</ul>
        </div>
    </>;
}
