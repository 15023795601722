import { callApi } from "./api";

const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return "";
  }

  const query = Object.keys(queryParams)
    .filter(key => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== "";
    })
    .map(key => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return query ? (withoutQuestionMark ? query : `?${query}`) : "";
};

export const getOffsetLimitFromPagination = ({ currentPage, pageSize }) => ({
  limit: pageSize,
  offset: (currentPage - 1) * pageSize,
});

class NotificationsService {
  async getNotificationsPaged({
    selectedFilters,
    isDeleteIncluded,
    currentPage,
    pageSize,
    cancelToken,
    dateCreatedRange
  }) {
    const { filters } = selectedFilters;

    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      advertiser__in: filters.partner_id && filters.partner_id.join(","),
      action__in: filters.action && filters.action.join(","),
      date_from:
        dateCreatedRange &&
        dateCreatedRange.from &&
        dateCreatedRange.from.format("YYYY-MM-DD HH:mm:ss"),
      date_to:
        dateCreatedRange &&
        dateCreatedRange.to &&
        dateCreatedRange.to.format("YYYY-MM-DD HH:mm:ss"),
      date_edited_from:
        filters.dateEditedRange &&
        filters.dateEditedRange.from &&
        filters.dateEditedRange.from.format("YYYY-MM-DD HH:mm:ss"),
      date_edited_to:
        filters.dateEditedRange &&
        filters.dateEditedRange.to &&
        filters.dateEditedRange.to.format("YYYY-MM-DD HH:mm:ss"),
      user__in: filters.user_id && filters.user_id.join(","),
      type: filters.type && filters.type,
      show_deleted: isDeleteIncluded ? 1 : 0,
    });
    const response = await callApi({
      url: `/api/notifications/${query}`,
      cancelToken,
    });
    return { results: response.data.results, count: response.data.count };
  }

  async getPartnersAutocomplete(searchTerm) {
    return await callApi({
      url: `/api/partners/autocomplete/?search=${searchTerm}`,
    });
  }

  async getCreators() {
    return await callApi({
      url: `/api/accounts/all/`,
    });
  }

  async createNotification(data) {
    return await callApi({
      url: "/api/notifications/create/",
      method: "POST",
      data,
    });
  }

  async getNotificationDetails(id) {
    return await callApi({
      url: `/api/notifications/${id}/`,
      method: "GET",
    });
  }

  async getNotificationHistory(id) {
    return await callApi({
      url: `/api/notifications/${id}/history/`,
      method: "GET",
    });
  }

  async editNotification(id, data) {
    return await callApi({
      url: `/api/notifications/${id}/edit/`,
      method: "POST",
      data,
    });
  }

  async deleteNotification(id) {
    return await callApi({
      url: `/api/notifications/${id}/delete/`,
      method: "DELETE",
    });
  }

  async deleteMultipleNotifications(notificationIds) {
    return await callApi({
      url: "/api/notifications/bulk_delete/",
      method: "DELETE",
      data: { notification_ids: notificationIds },
    });
  }
}

export const notificationsService = new NotificationsService();
