import React from "react";
import { Row } from "antd";
import styles from "../Preview.module.css";
import {
    renderCountries,
    renderOS,
    renderBrowsers,
    renderDays,
    renderHours,
    renderIpIsp,
    renderDeviceTypes,
    renderDeviceManufacturers,
    renderUserAge
} from '../utils/targetingItemRender';

export const TargetingInfo = ({ campaignGeneral, campaignTargeting, countriesAll, osAll, browserAll, typeDeviceAll, manufacturerAll, campaignType }) => {

    return <div className={styles.individualDetailsContainer}>
        <h3>Geo-Targeting</h3>
        <Row>
            {campaignTargeting?.countries?.items?.length > 0 ? (
                renderCountries(campaignTargeting.countries, countriesAll)
            ) : (
                <span className={styles.targetingInfoType}>No Country Targeting</span>
            )}
        </Row>
        {(campaignType === "Push" || campaignType === "Premium") &&
            <>
                <h3>User Age Targeting</h3>
                <Row className={styles.userAgeRow}>
                    {campaignTargeting.user_age ?
                        <span className={styles.targetingInfoValue}>
                            {campaignTargeting.user_age.from} - {campaignTargeting.user_age.to} hours
                        </span>
                        :
                        campaignTargeting?.age?.items?.length > 0 && renderUserAge(campaignTargeting.age)
                    }
                </Row>
            </>
        }
        <h3>Operating Systems</h3>
        <Row>
            {campaignTargeting?.os?.items?.length > 0 ? (
                renderOS(campaignTargeting.os.items, osAll)
            ) : (
                <span className={styles.targetingInfoType}>All Operating Systems</span>
            )}
        </Row>
        <h3>Browsers</h3>
        <Row>
            {campaignTargeting?.browsers?.items?.length > 0 ? (
                renderBrowsers(campaignTargeting.browsers.items, browserAll)
            ) : (
                <span className={styles.targetingInfoType}>All Browsers</span>
            )}
        </Row>
        <h3>Days</h3>
        <Row>
            {campaignTargeting?.day?.items?.length > 0 ? (
                renderDays(campaignTargeting.day)
            ) : (
                <span className={styles.targetingInfoType}>Every Day</span>
            )}
        </Row>
        <h3>Hours</h3>
        <Row>
            {campaignTargeting?.hour?.items?.length > 0 ? (
                renderHours(campaignTargeting.hour)
            ) : (
                <span className={styles.targetingInfoType}>24 hours a Day</span>
            )}
        </Row>
        <h3>Frequency Cap</h3>
        <Row>
            {campaignGeneral.frequency_cap && campaignGeneral.frequency_cap.impression !== null ? (
                <span className={styles.targetingInfoValue}>
                    {campaignGeneral.frequency_cap.impression.expiry_time}  hours
                </span>
            ) : (
                <span className={styles.targetingInfoType}>No Frequency Cap</span>
            )}
        </Row>
        <h3>IP & ISP</h3>
        <Row>
            {campaignTargeting?.ip?.items?.length > 0 ? (
                renderIpIsp(campaignTargeting.ip)
            ) : (
                <span className={styles.targetingInfoType}>No Targeting</span>
            )}
        </Row>
        <h3>Device Types</h3>
        <Row>
            {campaignTargeting?.device_types?.items?.length > 0 ? (
                renderDeviceTypes(campaignTargeting.device_types, typeDeviceAll)
            ) : (
                <span className={styles.targetingInfoType}>All Types</span>
            )}
        </Row>
        <h3>Device Manufacturers</h3>
        <Row>
            {campaignTargeting?.device_manufacturers?.items?.length > 0 ? (
                renderDeviceManufacturers(campaignTargeting.device_manufacturers, manufacturerAll)
            ) : (
                <span className={styles.targetingInfoType}>All Manufacturers</span>
            )}
        </Row>
    </div>
}
