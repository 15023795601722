import React from "react";
import { Select } from "antd";

const { Option } = Select;

export const generateCampaigns = (arr) => {
    return arr.map(campaign => (
        <Option key={campaign.id} value={campaign.id}>
            {campaign.name}
        </Option>
    ));
}

export const generatePartners = (arr) => {
    return arr.map(partner => (
        <Option key={partner.company_id} value={partner.company_id}>
            {partner.partner_name}
        </Option>
    ));
}

export const generateUsers = (arr) => {
    return arr.map(user => (
        <Option key={user.id} value={user.id}>
            {user.first_name + " " + user.last_name}
        </Option>
    ));
}