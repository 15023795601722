import React from "react";
import { Modal, Spin } from 'antd';
import ReactMarkdown from "react-markdown";

import styles from "../NotificationsTable.module.css";
import { LogTable } from "./LogTable";

export const DetailsModal = ({ notificationId, notificationDetails, loadDetails, setDetailsVisible, detailsVisible, setActionModalVisible, setActionType }) => {

    const goToEdit = (type) => {
        setDetailsVisible(false)
        setActionModalVisible(true)
        setActionType(type)
    }

    return (
        <Modal
            title={<span style={{ color: "#4D4F5C" }}>Notification details</span>}
            visible={detailsVisible}
            wrapClassName={styles.detailsModal}
            closable={false}
            centered={true}
            onOk={() => goToEdit("edit")}
            onCancel={() => setDetailsVisible(false)}
            okText="Edit details"
            okButtonProps={{ disabled: notificationDetails.action === 'Deleted' }}
            cancelText="Back to notifications"
        >
            <Spin spinning={loadDetails}>
                <div className={styles.infoContainer}>
                    <p>Notification name</p>
                    <p>{notificationDetails.title && notificationDetails.title}</p>
                </div>
                <div className={styles.infoContainer}>
                    <p>Description</p>
                    {notificationDetails.description && (
                        <ReactMarkdown children={notificationDetails.description} />
                    )}
                </div>
                <div className={styles.infoContainer}>
                    <p>Icon & type</p>
                    {notificationDetails.icon && notificationDetails.icon.src ?
                        <div>
                            <div className={styles.iconContainer}><img src={notificationDetails.icon.src && notificationDetails.icon.src} alt="" /></div>
                            <span>{notificationDetails.icon.text && notificationDetails.icon.text}</span>
                        </div>
                        : <span className={styles.noData}>No data</span>}
                </div>
                <div className={styles.infoContainer}>
                    <p>Creator</p>
                    <p>{notificationDetails.creator && notificationDetails.creator}</p>
                </div>
                <div className={styles.infoContainer}>
                    <p>Date created</p>
                    <p>{notificationDetails.date_created && notificationDetails.date_created}</p>
                </div>
                <div className={styles.infoContainer}>
                    <p>Edit log</p>
                    <LogTable notificationId={notificationId} />
                </div>
            </Spin>
        </Modal>
    )
}