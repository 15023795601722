import { callApi } from "./api";
import moment from "moment";

const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return "";
  }

  const query = Object.keys(queryParams)
    .filter(key => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== "";
    })
    .map(key => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return query ? (withoutQuestionMark ? query : `?${query}`) : "";
};

export const getOffsetLimitFromPagination = ({ currentPage, pageSize }) => ({
  limit: pageSize,
  offset: (currentPage - 1) * pageSize,
});

class AccountStatements {
  async getStatementsPaged({
    selectedFilters,
    currentPage,
    pageSize,
    cancelToken,
  }) {
    const { filters, dateRange } = selectedFilters;
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      date_from: moment(dateRange.from).format("YYYY-MM-DD"),
      date_to: moment(dateRange.to).format("YYYY-MM-DD"),
      partner: filters.partner_id && filters.partner_id.join(","),
      statement_type: filters.type && filters.type,
      platform: filters.platform && filters.platform,
    });
    const response = await callApi({
      url: `/api/billing/account_statements/${query}`,
      cancelToken,
    });
    return {
      results: response.data.items,
      summary: response.data.items_totals,
      count: response.data.total,
    };
  }

  async getPartnersAutocomplete(searchTermPartner) {
    return await callApi({
      url: `/api/partners/autocomplete/?search=${searchTermPartner}`,
    });
  }
}

export const accountStatementsService = new AccountStatements();
