import axiosStatic from "axios";

import { API_BASE_URL } from "../config";
import { openErrorNotification } from "../utils/notifications";
import { getQueryString } from "../utils/query";

const axiosInstance = axiosStatic.create({
  baseURL: API_BASE_URL,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      window.location.href = `/login/${getQueryString({
        next: window.location.pathname,
      })}`;
    } else if (error.response && error.response.status === 403) {
      openErrorNotification({
        message: "You do not have permission to perform this action.",
      });
    }
    // TODO: some error message if something else fails (not 401 or 403) ?
    return Promise.reject(error);
  }
);

// TODO: add more options
export const callApi = ({
  url,
  method,
  data,
  cancelToken,
  headers,
  responseType,
}) =>
  axiosInstance({
    url,
    method: method || "GET",
    data,
    cancelToken,
    headers,
    responseType,
  }).then(response => response.data);

export const getCancelToken = () => axiosStatic.CancelToken.source();

export const isCancelled = error => axiosStatic.isCancel(error);
