import React from 'react';
import { Row } from 'antd';
import styles from '../Preview.module.css';
import { renderCategories, renderSubIDPlacements } from '../utils/targetingItemRender';

export const TrafficInfo = ({ campaignTargeting, categoriesAll }) => {
    return (
        <div className={styles.individualDetailsContainer}>
            <h3>Traffic Categories</h3>
            <Row>
                {campaignTargeting?.keyword?.items ? (
                    renderCategories(campaignTargeting.keyword, categoriesAll)
                ) : (
                    <span className={styles.targetingInfoType}>All Categories</span>
                )}
            </Row>
            <h3>SubID & Placements</h3>
            <Row>
                {campaignTargeting?.subids?.items?.length > 0 ? (
                    renderSubIDPlacements(campaignTargeting.subids)
                ) : (
                    <span className={styles.targetingInfoType}>No Targeting</span>
                )}
            </Row>
        </div>
    );
};
