import React from "react";

import styles from "../ActivityLog.module.css";

export const CampaignActionCol = ({ status }) => {
  return (
    <div className={`${styles.status} ${styles["status" + status.toLowerCase()]}`}>
      {status}
    </div>
  );
};
