import React from 'react';
import { Popover, Button, Checkbox, Row, Col } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useState } from 'react';
import styles from '../Transactions.module.css';

const columnsData = [
  { value: 'id', text: 'ID' },
  { value: 'partner', text: 'Partner name' },
  { value: 'amount_usd_original', text: 'Amount USD' },
  { value: 'payment_type', text: 'Payment type' },
  { value: 'transaction_status', text: 'Status' },
  { value: 'parent_id', text: 'Parent ID' },
  { value: 'date_created', text: 'Date created' },
  { value: 'order_id', text: 'Order ID' },
  { value: 'customer_first_name', text: 'Customer first name' },
  { value: 'customer_last_name', text: 'Customer last name' },
  { value: 'customer_email', text: 'Customer e-mail' },
];

export const ColumnsGroup = ({
  onChange,
  checkedColumns,
  showUpdateButton,
  onUpdate
}) => {
  const renderColumns = () => {
    const renderRows = (perRow) => {
      let rows = [];
      for (let i = 0; i < columnsData.length; i += perRow) {
        const rowCols = [];
        for (let j = i; j < i + perRow; j++) {
          const column = columnsData[j];
          if (column) {
            const col = (
              <Col span={8} key={column.value}>
                <Checkbox
                  value={column.value}
                  checked={!!checkedColumns[column.value]}
                  onChange={({ target }) => onChange({ ...checkedColumns, [target.value]: target.checked })}
                >
                  {column.text}
                </Checkbox>
              </Col>
            );
            rowCols.push(col);
          }
        }
        const row = (
          <Row style={{ marginBottom: '10px' }} key={i}>
            {rowCols}
          </Row>
        );
        rows.push(row);
      }
      return rows;
    };

    return (
      <div className={styles.columnGroupContainer}>
        {renderRows(3)}
      </div>
    );
  };
  const [columnsVisibility, setColumnsVisibility] = useState(false);
  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      visible={columnsVisibility}
      onVisibleChange={() => setColumnsVisibility((prevState) => !prevState)}
      content={renderColumns()}
    >
      <Button>
        <MenuOutlined rotate={90} />
        Columns
      </Button>
    </Popover>
  );
};
