import styles from "../AllCampaigns.module.css";
import moment from "moment";
import { StatusTableCol } from "../components/StatusTableCol";
import { CampaignTypeCol } from '../components/CampaignTypeCol';

export const allColumns = [
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    className: styles.statusColumn,
    render: status => {
      return <StatusTableCol status={status} />;
    },
  },
  {
    title: "Partner name",
    dataIndex: "partner",
  },
  {
    title: "Campaign ID",
    dataIndex: "id",
  },
  {
    title: "Campaign name",
    dataIndex: "name",
  },
  {
    title: "Campaign type",
    dataIndex: "type",
    align: "center",
    render: (campaignType) => {
      return <CampaignTypeCol campaignType={campaignType} />;
    }
  },
  {
    title: "Date created",
    dataIndex: "date_created",
    render: date_created =>
      date_created && moment(date_created).format("DD MMM YYYY hh:mm:ss"),
  },
  {
    title: "Budget",
    dataIndex: "budget",
  },
  {
    title: "Spent",
    dataIndex: "spent",
    render: spent => spent && "$ " + parseFloat(spent).toFixed(3),
  }
];
