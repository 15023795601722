import React from "react";
import { Button, Select, Row, Col, Input, DatePicker, Radio } from "antd";
import { SwapOutlined } from '@ant-design/icons';
import { generatePartners } from '../utils/filterOptions';
import { ColumnsGroup } from "./ColumnsGroup";
import moment from 'moment';

import styles from "../Transactions.module.css";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const Filters = ({
  partnersList,
  autocompletePartnersLoading,
  setSearchPartner,
  checkedColumns,
  setCheckedColumns,
  dateRange,
  setDateRange,
  submit,
  filters,
  setFilters
}) => {
  return (
    <>
      <div className={styles.filtersContainer}>
        <Radio.Group
          className={styles.dateRadioSelect}
          defaultValue="today"
          size="large"
        >
          <Radio.Button
            value="today"
            onClick={() => setDateRange({ from: moment(), to: moment() })}
          >
            Today
          </Radio.Button>
          <Radio.Button
            value="yesterday"
            onClick={() =>
              setDateRange({
                from: moment().subtract(1, "days"),
                to: moment().subtract(1, "days"),
              })
            }
          >
            Yesterday
          </Radio.Button>
          <Radio.Button
            value="7days"
            onClick={() =>
              setDateRange({
                from: moment().subtract(6, "days"),
                to: moment(),
              })
            }
          >
            Last 7 Days
          </Radio.Button>
          <Radio.Button
            value="30days"
            onClick={() =>
              setDateRange({
                from: moment().subtract(30, "days"),
                to: moment(),
              })
            }
          >
            Last 30 Days
          </Radio.Button>
          <Radio.Button
            value="thisMonth"
            onClick={() =>
              setDateRange({
                from: moment().startOf("month"),
                to: moment().endOf("month"),
              })
            }
          >
            This Month
          </Radio.Button>
          <Radio.Button
            value="lastMonth"
            onClick={() =>
              setDateRange({
                from: moment().subtract(1, "months").startOf("month"),
                to: moment().subtract(1, "months").endOf("month"),
              })
            }
          >
            Last Month
          </Radio.Button>
        </Radio.Group>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]} style={{ marginBottom: 16 }}>
          <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <RangePicker
              styles={{ width: "100%", borderRadius: 5 }}
              allowClear={false}
              onChange={(momentDates, dates) => {
                setDateRange({ from: momentDates[0], to: momentDates[1] });
              }}
              value={[moment(dateRange.from), moment(dateRange.to)]}
            />
          </Col>
        </Row>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]} style={{ alignItems: 'flex-end' }}>
          <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Company or partner</span>
            <Select
              mode="multiple"
              onChange={value => setFilters({ ...filters, partner_id: value })}
              style={{ width: "100%" }}
              placeholder="All companies and partners"
              onSearch={setSearchPartner}
              value={filters.partner_id}
              loading={autocompletePartnersLoading}
              filterOption={false}
              allowClear
            >
              {generatePartners(partnersList || [])}
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Status</span>
            <Select
              size="medium"
              mode="multiple"
              className={styles.selectFilter}
              placeholder="All Statuses"
              allowClear
              onChange={(value) => {
                setFilters({ ...filters, status: value });
              }}
              value={filters.status}
            >
              <Option value="init">Initialized</Option>
              <Option value="completed">Completed</Option>
              <Option value="failed">Failed</Option>
              <Option value="cancelled">Cancelled</Option>
              <Option value="processing">Processing</Option>
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Payment type</span>
            <Select
              size="medium"
              mode="multiple"
              className={styles.selectFilter}
              placeholder="All Types"
              allowClear
              onChange={(value) => {
                setFilters({ ...filters, type: value });
              }}
              value={filters.type}
            >
              <Option value="wire">Wire</Option>
              <Option value="card">Card</Option>
              <Option value="paypal">PayPal</Option>
              <Option value="payoneer">Payoneer</Option>
              <Option value="stripe">Stripe</Option>
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Search</span>
            <Input
              onChange={(e) =>
                setFilters({ ...filters, search: e.target.value })
              }
              style={{ width: "100%" }}
              placeholder="Search by keyword"
              value={filters.search}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Button
              className={styles.updateButton}
              type="primary"
              size="medium"
              onClick={() => submit(filters)}
              icon={<SwapOutlined />}
            >
              Update
            </Button>
          </Col>
        </Row>
      </div>
      <div className={styles.extActionGroup}>
        <ColumnsGroup
          className={styles.columnsBtn}
          onChange={value => {
            setCheckedColumns(value);
          }}
          showUpdateButton={true}
          onUpdate={() => {
            submit(filters);
          }}
          checkedColumns={checkedColumns}
        />
      </div>
    </>
  );
};
