import styles from "../Dashboard.module.css";
import moment from "moment";
import { StatusTableCol } from "../components/StatusTableCol";
import { CampaignTypeCol } from '../components/CampaignTypeCol';
import { Tooltip, Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { openSuccessNotification } from '../../../utils/notifications';

export const allColumns = [
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    className: styles.statusColumn,
    render: status => {
      return <StatusTableCol status={status} />;
    },
  },
  {
    title: "Partner name",
    dataIndex: "partner",
  },
  {
    title: "Campaign ID",
    dataIndex: "id",
  },
  {
    title: "Campaign name",
    dataIndex: "name",
  },
  {
    title: "Campaign type",
    dataIndex: "type",
    align: "center",
    render: (campaignType) => {
      return <CampaignTypeCol campaignType={campaignType} />;
    }
  },
  {
    title: "Date created",
    dataIndex: "date_created",
    render: date_created =>
      date_created && moment(date_created).format("DD MMM YYYY hh:mm:ss"),
  },
  {
    title: "Budget",
    dataIndex: "budget",
  },
];

export const subColumns = [
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    className: styles.statusColumn,
    render: status => {
      return <StatusTableCol status={status} />;
    },
  },
  {
    title: "Creative headline",
    dataIndex: "title",
  },
  {
    title: "Creative description",
    dataIndex: "description",
  },
  {
    title: "Landing URL",
    dataIndex: "landing_url",
    width: 200,
    render: url => {
      return <div className={styles.urlWrapper}>
        <div className={styles.url}>
          <span>{url}</span>
        </div>
        <div className={styles.urlAction}>
          <Tooltip title="Copy full URL">
            <CopyToClipboard text={url} onCopy={() => openSuccessNotification({ message: 'Successfully copied to clipboard!' })}>
              <Button style={{ color: "#737373" }} icon={<CopyOutlined />} />
            </CopyToClipboard>
          </Tooltip>
        </div>
      </div>
    }
  },
  {
    title: "Creative icon",
    dataIndex: "icon",
    align: "center",
    render: icon => {
      return <img alt="creative-icon" style={{ height: '40px' }} src={icon} />;
    },
  },
  {
    title: "Creative image",
    dataIndex: "image",
    align: "center",
    render: image => {
      return <img alt="creative-img" style={{ height: '40px' }} src={image} />;
    },
  },
];
