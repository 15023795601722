import styles from "../Transactions.module.css";
import moment from "moment";
import { StatusTableCol } from "../components/StatusTableCol";
import { typeName } from "./filterOptions"

export const allColumns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Partner & company name",
    dataIndex: "partner",
  },
  {
    title: "Amount USD",
    dataIndex: "amount_usd_original",
    render: amount_usd_original => {
      return "$ " + amount_usd_original;
    },
  },
  {
    title: "Payment type",
    dataIndex: "payment_type",
    render: payment_type => typeName(payment_type),
  },
  {
    title: "Status",
    dataIndex: "transaction_status",
    align: "center",
    className: styles.statusColumn,
    render: transaction_status => {
      return <StatusTableCol status={transaction_status} />;
    },
  },
  {
    title: "Parent ID",
    dataIndex: "parent_id",
  },
  {
    title: "Date created",
    dataIndex: "date_created",
    render: date_created =>
      date_created && moment(date_created).format("DD MMM YYYY hh:mm:ss"),
  },
  {
    title: "Order ID",
    dataIndex: "order_id",
  },
  {
    title: "Customer first name",
    dataIndex: "customer_first_name",
  },
  {
    title: "Customer last name",
    dataIndex: "customer_last_name",
  },
  {
    title: "Customer e-mail",
    dataIndex: "customer_email",
  },
];
