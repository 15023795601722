import React from "react";
import { Table as AntTable, Pagination, Button, Modal } from "antd";
import { CheckOutlined, PauseOutlined } from '@ant-design/icons';
import styles from "../AllCampaigns.module.css";

import { updateCampaigns } from "../utils";

export const Table = ({
  campaigns,
  totalRows,
  currentPage,
  pageSize,
  loadingCampaigns,
  onPaginationChange,
  tableColumns,
  filters,
  dispatchSelectedFilters,
  selectedRowKeys,
  setSelectedRowKeys
}) => {

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.status !== 'suspended' && record.status !== 'active',
    })
  };

  const displayModal = (action) => {
    Modal.confirm({
      centered: true,
      width: 500,
      title: 'Careful!',
      icon: '',
      content: <>
        <p>You are about to <span style={{fontWeight: '600', color: action === 'active' ? '#58A54F' : '#D85C5C'}}>{action === 'active' ? 'approve' : 'reject'}</span> all of the selected campaigns. You currently have <span style={{fontWeight: '600'}}>{selectedRowKeys.length} campaigns</span> selected.</p>
        <p>Are you sure you wish to proceed?</p>
      </>,
      onOk: () => updateCampaigns(selectedRowKeys, action, filters, dispatchSelectedFilters, setSelectedRowKeys),
      okText: `${action === 'active' ? 'Approve' : 'Reject'} all campaigns`,
      cancelText: 'Go back to campaigns',
    });
  }

  return (
    <>
      <div className={styles.actionButtonsCheckbox}>
        {selectedRowKeys.length > 0 ?
          <>
            <Button ghost onClick={() => displayModal('active')} className={styles.approveSelected} type="primary" icon={<CheckOutlined />}>
              Approve
            </Button>
            <Button ghost onClick={() => displayModal('suspended')} className={styles.suspendSelected} type="primary" icon={<PauseOutlined />}>
              Suspend
            </Button>
          </>
          : ""
        }
      </div>

      <AntTable
        rowSelection={rowSelection}
        className={styles.dataTable}
        columns={tableColumns}
        dataSource={campaigns}
        loading={loadingCampaigns}
        pagination={false}
        rowKey={({ id }) => id}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalRows}
              current={currentPage}
              pageSize={pageSize}
              showSizeChanger
              pageSizeOptions={["10", "20", "30", "50", "100"]}
              onChange={onPaginationChange}
              onShowSizeChange={onPaginationChange}
            />
          );
        }}
        scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
      />
    </>
  );
};
