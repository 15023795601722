import React, { useState, useEffect, useReducer } from "react";
import { useFetch } from "../../utils/hooks/useFetch";
import { useDebounce } from "../../utils/hooks/useDebounce";

import { Filters } from "./components/Filters";
import { Table } from "./components/Table";
import { filtersReducer } from "./utils/reducers";

import { allColumns } from "./utils/columns";
import { campaignsService } from "../../services/campaigns";
import { transactionsService } from "../../services/transactions";

import moment from "moment";

import styles from "./Transactions.module.css";

const initialColumns = {
  id: true,
  partner: true,
  amount_usd_original: true,
  payment_type: true,
  transaction_status: true,
  parent_id: true,
  date_created: true,
};

export const TransactionsTable = () => {
  const [filters, setFilters] = useState({});
  const [selectedFilters, dispatchSelectedFilters] = useReducer(
    filtersReducer,
    {}
  );
  const [dateRange, setDateRange] = useState({ from: moment(), to: moment() });
  const [checkedColumns, setCheckedColumns] = useState(initialColumns);
  const [tableColumns, setTableColumns] = useState(allColumns);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);

  const [partnersList, setPartnersList] = useState([]);
  const [autocompletePartnersLoading, setAutocompletePartnersLoading] = useState(false);
  const [searchPartner, setSearchPartner] = useState("");
  const debouncePartner = useDebounce(searchPartner, 500);

  const loadTransactions = cancelToken =>
    transactionsService.getTransactionsPaged({
      dateRange,
      selectedFilters,
      currentPage,
      pageSize,
      cancelToken,
    });

  const [
    {
      data: { results: transactions, count: totalRows },
      isFetching,
    },
    getTransactions,
  ] = useFetch(loadTransactions);

  useEffect(() => {
    const getPartners = async (searchPartner) => {
      setAutocompletePartnersLoading(true);
      try {
        const res = await campaignsService.getPartnersAutocomplete(
          searchPartner
        );
        let newPartners = [...res.data];
        setPartnersList(newPartners);
      } catch (e) {
        console.log(e);
      } finally {
        setAutocompletePartnersLoading(false);
      }
    };
    getPartners(debouncePartner);
  }, [debouncePartner, transactions]);

  useEffect(() => {
    if (selectedFilters.filters) {
      getTransactions();
    }
  }, [currentPage, pageSize, getTransactions, selectedFilters]);

  useEffect(() => {
    const filteredColumns = allColumns.filter(item => {
      return Object.keys(checkedColumns).some(key => {
        return checkedColumns[key] && key === item.dataIndex;
      });
    });
    setTableColumns(filteredColumns);
  }, [selectedFilters, checkedColumns]);

  return <>
    <div className={styles.mainContainer}>
      <div className={styles.pageTitle}>
        <h4>Transactions</h4>
        <p>View user transactions.</p>
      </div>
      <Filters
        partnersList={partnersList}
        autocompletePartnersLoading={autocompletePartnersLoading}
        setSearchPartner={setSearchPartner}
        checkedColumns={checkedColumns}
        setCheckedColumns={setCheckedColumns}
        dateRange={dateRange}
        setDateRange={setDateRange}
        submit={filters => {
          dispatchSelectedFilters({ type: "", payload: { filters } });
        }}
        filters={filters}
        setFilters={setFilters}
      />
      <Table
        tableColumns={tableColumns}
        transactions={transactions}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loadingTransactions={isFetching}
        getTransactions={getTransactions}
        onPaginationChange={(currentPage, pageSize) => {
          setCurrentPage(currentPage);
          setPageSize(pageSize);
        }}
        selectedFilters={selectedFilters}
        filters={filters}
        dispatchSelectedFilters={dispatchSelectedFilters}
      />
    </div>
  </>
};
