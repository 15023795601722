import React, { useState, useEffect, useMemo, useReducer } from "react";
import { useFetch } from "../../utils/hooks/useFetch";
import { useDebounce } from "../../utils/hooks/useDebounce";

import { AllCampaignsFilters } from "./components/AllCampaignsFilters";
import { Table } from "./components/Table";
import { filtersReducer } from "./utils/reducers";

import { allColumns } from "./utils/columns";
import { ActionsTableCol } from "./components/ActionsTableCol";
import { campaignsService } from "../../services/campaigns";
import styles from "./AllCampaigns.module.css";
import { CampaignPreview } from "../../components/CampaignPreview";

export const AllCampaigns = () => {
  const [filters, setFilters] = useState({});
  const [selectedFilters, dispatchSelectedFilters] = useReducer(
    filtersReducer,
    {}
  );
  const [initialColumns, setInitialColumns] = useState(allColumns);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [campaignsList, setCampaignsList] = useState([]);
  const [partnersList, setPartnersList] = useState([]);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [campaignId, setCampaignId] = useState();

  const [autocompletePartnersLoading, setAutocompletePartnersLoading] = useState(false);
  const [autocompleteAllCampaignsLoading, setAutocompleteAllCampaignsLoading] = useState(false);

  const [searchCampaign, setSearchCampaign] = useState("");
  const debounceCampaign = useDebounce(searchCampaign, 1000);

  const [searchPartner, setSearchPartner] = useState("");
  const debouncePartner = useDebounce(searchPartner, 500);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const loadCampaigns = cancelToken =>
    campaignsService.getAllCampaignsPaged({
      selectedFilters,
      initialColumns,
      currentPage,
      pageSize,
      cancelToken,
    });

  useEffect(() => {
    dispatchSelectedFilters({ type: "", payload: { filters } });
  }, []);

  const [
    {
      data: { results: campaigns, count: totalRows },
      isFetching,
    },
    getCampaigns,
  ] = useFetch(loadCampaigns);

  useEffect(() => {
    const getCampaignsByNameOrID = async (searchCampaign) => {
      setAutocompleteAllCampaignsLoading(true);
      try {
        const res = await campaignsService.getAllCampaignsAutocomplete(
          searchCampaign
        );
        let newCampaigns = [...res.data];
        setCampaignsList(newCampaigns);
      } catch (e) {
        console.log(e);
      } finally {
        setAutocompleteAllCampaignsLoading(false);
      }
    };
    if (campaigns.length !== 0) getCampaignsByNameOrID(debounceCampaign);
  }, [debounceCampaign, campaigns]);

  useEffect(() => {
    const getPartners = async (searchPartner) => {
      setAutocompletePartnersLoading(true);
      try {
        const res = await campaignsService.getPartnersAutocomplete(
          searchPartner
        );
        let newPartners = [...res.data];
        setPartnersList(newPartners);
      } catch (e) {
        console.log(e);
      } finally {
        setAutocompletePartnersLoading(false);
      }
    };
    if (campaigns.length !== 0) getPartners(debouncePartner);
  }, [debouncePartner, campaigns]);


  const actionTableColumn = useMemo(() => {
    return {
      title: "Actions",
      key: "actions",
      fixed: "right",
      align: "center",
      render: ({ id, status }) => (
        <ActionsTableCol
          id={id}
          status={status}
          filters={filters}
          setCampaignId={setCampaignId}
          setPreviewVisible={setPreviewVisible}
          dispatchSelectedFilters={dispatchSelectedFilters}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      ),
    };
  }, [filters]);

  const [tableColumns, setTableColumns] = useState([
    ...allColumns,
    actionTableColumn,
  ]);

  useEffect(() => {
    if (selectedFilters.filters) {
      getCampaigns();
    }
  }, [currentPage, pageSize, getCampaigns, selectedFilters]);

  return <>
    <div className={styles.mainContainer}>
      <div className={styles.pageTitle}>
        <h4>All Campaigns</h4>
        <p>Check out all Advertiser platform campaigns here.</p>
      </div>
      <AllCampaignsFilters
        campaignsList={campaignsList}
        autocompletePartnersLoading={autocompletePartnersLoading}
        autocompleteAllCampaignsLoading={autocompleteAllCampaignsLoading}
        setSearchCampaign={setSearchCampaign}
        setSearchPartner={setSearchPartner}
        partnersList={partnersList}
        submit={filters => {
          dispatchSelectedFilters({ type: "", payload: { filters } });
        }}
        filters={filters}
        setFilters={setFilters}
      />
      <Table
        tableColumns={tableColumns}
        setTableColumns={setTableColumns}
        campaigns={campaigns}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loadingCampaigns={isFetching}
        getCampaigns={getCampaigns}
        onPaginationChange={(currentPage, pageSize) => {
          setCurrentPage(currentPage);
          setPageSize(pageSize);
        }}
        initialColumns={initialColumns}
        setInitialColumns={setInitialColumns}
        selectedFilters={selectedFilters}
        filters={filters}
        dispatchSelectedFilters={dispatchSelectedFilters}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
      <CampaignPreview campaignId={campaignId} setPreviewVisible={setPreviewVisible} previewVisible={previewVisible} />
    </div>
  </>
};
