import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { notificationsService } from "../../../services/notifications";

export const LogTable = ({ notificationId }) => {

    const [historyData, setHistoryData] = useState([]);
    const [loadHistory, setLoadHistory] = useState(false);

    const tableColumns = [
        {
            title: "Date edited",
            dataIndex: "date_edited",
        },
        {
            title: "Action",
            dataIndex: "action",
        },
        {
            title: "Action taken by",
            dataIndex: "creator",
        }
    ];

    const refactoredHistoryData = (data) => {
        const dataFormated = [...data];
        dataFormated.map((item) => {
            if (item.action === "Created") {
                dataFormated.pop(item)
            }
        })
        return dataFormated;
    }

    useEffect(() => {
        const getDetails = async () => {
            setLoadHistory(true);
            try {
                const res = await notificationsService.getNotificationHistory(notificationId);
                if (res.success) {
                    setHistoryData(refactoredHistoryData(res.data));
                }
            } catch (e) {
                console.log(e);
            } finally {
                setLoadHistory(false);
            }
        };
        getDetails();
    }, [notificationId]);

    return (
        <Table
            columns={tableColumns}
            dataSource={historyData}
            loading={loadHistory}
            rowKey={({ id }) => id}
            scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
            pagination={{ pageSize: 5, total: historyData.length }}
        />
    );
};
