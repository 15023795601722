import React, { useState } from "react";
import { Modal } from 'antd';

import styles from "../NotificationsTable.module.css";
import { notificationsService } from "../../../services/notifications";
import { openErrorNotification, openSuccessNotification } from "../../../utils/notifications";

export const DeleteModal = ({ notificationId, deleteVisible, setDeleteVisible, filters, dispatchSelectedFilters, setSelectedRowKeys }) => {

    const [loadDelete, setLoadDelete] = useState(false);

    const deleteNotification = async () => {
        setLoadDelete(true)
        try {
            const res = await notificationsService.deleteNotification(notificationId);
            if (res.success) {
                openSuccessNotification({ message: res.message });
                setDeleteVisible(false);
                setSelectedRowKeys([]);
                dispatchSelectedFilters({ type: "", payload: { filters } });
            } else {
                openErrorNotification({ message: res.message });
            }
        } catch (e) {
            openErrorNotification({ message: e.message });
        } finally {
            setLoadDelete(false)
        }
    }

    return (
        <Modal
            title={<span style={{ color: "#4D4F5C" }}>Careful!</span>}
            visible={deleteVisible}
            wrapClassName={styles.deleteModal}
            closable={false}
            centered={true}
            onOk={deleteNotification}
            confirmLoading={loadDelete}
            onCancel={() => setDeleteVisible(false)}
            okText="Delete Notification"
            cancelText="Cancel"
        >
            <p>You are about to <span style={{ color: "#D85C5C", fontWeight: "600" }}>delete</span> this notification for all of the users. Are you sure you wish to proceed?</p>
        </Modal>
    )
}