const groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x.id);
        return rv;
    }, {});
};

export const parseCreatives = (creatives) => {
    const finalObj = {};
    const reducedCreatives = creatives.reduce((parsed, item) => {
        parsed.names.push({ value: item.name, id: item.id })
        parsed.titles.push({ value: item.title, id: item.id })
        parsed.descriptions.push({ value: item.description, id: item.id })
        parsed.icons.push({ value: item.icon, id: item.id })
        parsed.images.push({ value: item.image, id: item.id })
        parsed.landing_urls.push({ value: item.landing_url, id: item.id })
        return parsed
    }, { names: [], titles: [], descriptions: [], icons: [], images: [], landing_urls: [] })

    Object.keys(reducedCreatives).forEach((key) => {
        const grouped = groupBy(reducedCreatives[key], 'value')
        const values = Object.values(grouped);

        const parsedGroups = Object.keys(grouped).filter(item => item !== 'null').map((item, id) => {
            return {
                group: key.slice(0, -1),
                value: item,
                ids: values[id],
                action: null
            }
        })

        finalObj[key] = parsedGroups
    })
    return finalObj
}


export const reconstructCreatives = (creatives, initialCreatives) => {
    const reconstructedCreatives = {};
    for (const value of [].concat.apply([], Object.values(creatives))) {
        value.ids.forEach(id => {
            const rejected = value.action === 'reject' ? [value.group] : [];
            const action = reconstructedCreatives[id] && (reconstructedCreatives[id].action === 'reject' || value.action === 'reject' ? 'reject' : reconstructedCreatives[id].action === null || value.action === null ? null : value.action ? value.action : reconstructedCreatives[id].action);

            reconstructedCreatives[id] = {
                description: null,
                icon: null,
                image: null,
                ...reconstructedCreatives[id],
                [value.group]: value.value,
                id: id,
                action,
                rejected: reconstructedCreatives[id] ? [...reconstructedCreatives[id].rejected, ...rejected] : []
            };
        });
    }

    const parsedCreatives = Object.values(reconstructedCreatives);

    return parsedCreatives.map(parsed => {
        const original = initialCreatives.find(obj => obj.id === parsed.id);
        return {
            ...parsed,
            actions: original?.actions
        };
    });
}
