import { callApi } from "./api";

const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return "";
  }
  const query = Object.keys(queryParams)
    .filter(key => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== "";
    })
    .map(key => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");
  return query ? (withoutQuestionMark ? query : `?${query}`) : "";
};

export const getOffsetLimitFromPagination = ({ currentPage, pageSize }) => ({
  limit: pageSize,
  offset: (currentPage - 1) * pageSize,
});

class ActivityLogService {
  async getAllDeviceTypes() {
    return await callApi({ url: "/api/device_types" });
  }

  async getAllDeviceManufacturers() {
    return await callApi({ url: "/api/device_manufacturers" });
  }

  async getActivityLogsPaged({
    currentPage,
    pageSize,
    cancelToken,
    selectedFilters,
  }) {
    const { filters } = selectedFilters;
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      action: filters.campaignAction && filters.campaignAction.join(","),
      id: filters.campaignsList && filters.campaignsList.join(","),
      type: filters.campaignType && filters.campaignType.join(","),
      partner: filters.partnersList && filters.partnersList.join(","),
      user: filters.usersList && filters.usersList.join(","),
      date: filters.date && filters.date.format("YYYY-MM-DD"),
    });
    const response = await callApi({
      url: `api/activities/${query}`,
      cancelToken,
    });
    return { results: response.results, count: response.count };
  }

  async getAllCampaignsAutocomplete(searchTermCmp) {
    return await callApi({
      url: `/api/campaigns/all/autocomplete/?search=${searchTermCmp}`,
    });
  }

  async getCampaignDetails(campaignId) {
    return await callApi({ url: `/api/campaigns/${campaignId}` });
  }

  async getCampaignCreatives(campaignId) {
    return await callApi({ url: `/api/creatives/${campaignId}` });
  }

  async getPartnersAutocomplete(searchTerm) {
    return await callApi({
      url: `/api/partners/autocomplete/?search=${searchTerm}`,
    });
  }

  async getAllUsers() {
    return await callApi({ url: `/api/accounts/all/` });
  }

  async approveCampaign(id, data) {
    return await callApi({
      url: `api/campaigns/${id}/approve/`,
      method: "POST",
      data,
    });
  }
}

export const activityLogService = new ActivityLogService();
