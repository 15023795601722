import React, { useContext } from "react";
import { Layout, Row, Col, Menu, Dropdown } from "antd";
import {
  LoadingOutlined,
  DownOutlined,
  CloseOutlined,
  MenuOutlined
} from "@ant-design/icons";

import UserContext from "../../contexts/userContext";
import styles from "../Layout.module.css";
import { MenuOptions } from "./MenuOptions";

export const Header = ({
  collapsed,
  setCollapsed,
  isSmallScreen
}) => {
  const { userInfo, loading } = useContext(UserContext);

  const headerStyles = {
    background: "#fff",
    padding: 0,
    // position: "fixed",
    // zIndex: 3,
    // float: "right",
    // width: `calc(100% - ${collapsed ? 80 : 200}px)`,
    // boxShadow: "0px 2px 6px #EFF2F5",
  };

  const iconStyles = {
    background: "#1179BF",
    display: "flex",
    position: "absolute",
    zIndex: 100,
    top: 12,
    transition: ".2s",
    left: collapsed ? 0 : 200,
    cursor: "pointer",
    padding: "3px 5px",
    borderRadius: "0 3px 3px 0",
  };

  return (
    <>
      <Layout.Header style={{ ...headerStyles }}>
        {isSmallScreen ? (
          <span
            style={{ ...iconStyles }}
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? (
              <MenuOutlined style={{ fontSize: 32, color: "#fff" }} />
            ) : (
              <CloseOutlined style={{ fontSize: 32, color: "#fff" }} />
            )}
          </span>
        ) : null}
        <Row
          style={{
            flexWrap: "nowrap",
            maxHeight: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Col style={{ flex: 1 }}></Col>
          <Col style={{ flex: 1 }} className={styles.headerRight}>
            <Dropdown
              overlayClassName={styles.headerDropdown}
              overlay={
                <Menu>
                  <MenuOptions userInfo={userInfo} />
                </Menu>
              }
            >
              <span className={styles.headerRightUser}>
                <div className={styles.headerUsername}>
                  {!loading ? (
                    userInfo.first_name ? (
                      <span className={styles.headerHighligh}>
                        {userInfo.first_name} {userInfo.last_name}
                      </span>
                    ) : (
                      "N/A"
                    )
                  ) : (
                    <LoadingOutlined spin />
                  )}
                </div>
                <DownOutlined className={styles.downArrow} />{" "}
                {userInfo ? (
                  <img
                    className={styles.profileAvatar}
                    src={userInfo.image ? userInfo.image : "/images/no-profile.jpg"}
                    alt="avatar"
                  />
                ) : null}
              </span>
            </Dropdown>
          </Col>
          <div style={{ width: "20px" }}></div>
        </Row>
      </Layout.Header>
    </>
  );
};
