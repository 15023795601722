import React, { useEffect } from "react";
import { Form, Input } from "antd";
import styles from "../MyProfile.module.css";

export const PersonalDetails = ({ userInformations }) => {

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(userInformations)
     }, [form, userInformations]);

    return(
        <div className={styles.personalDetailsContent}>
            <Form
            form={form}
            name="userForm"
            layout="vertical">
                <div className={styles.personalDetailsTop}>
                    <div className={styles.imageUpload}>
                        <div className={styles.imagePreviewModal}>
                        <img
                            src="https://i.pinimg.com/originals/45/d9/8a/45d98aa922bef6b5213b488dc36a8764.png"
                            alt="Preview"
                        />
                        </div>
                    </div>
                    <div className={styles.userInfo}>
                        <div>
                            <p>User Information</p>
                            <Form.Item name="username" label="Username">
                                <Input disabled placeholder="Username" />
                            </Form.Item>
                            <Form.Item name="first_name" label="First Name">
                                <Input disabled placeholder="First Name" />
                            </Form.Item>
                            <Form.Item name="last_name" label="Last Name">
                                <Input disabled placeholder="Last Name" />
                            </Form.Item>
                        </div>
                        <div className={styles.userInfoLeft}>
                            <p>Contact details</p>
                            <Form.Item name="email" label="E-mail">
                                <Input disabled placeholder="E-mail"/>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )}