import React from "react";
import { Table as AntTable, Pagination, Button, Modal } from "antd";
import styles from "../NotificationsTable.module.css";

import { deleteNotifications } from "../utils";

export const Table = ({
  notifications,
  totalRows,
  currentPage,
  pageSize,
  loadingNotifications,
  filters,
  dispatchSelectedFilters,
  onPaginationChange,
  tableColumns,
  selectedRowKeys,
  setSelectedRowKeys
}) => {

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.action === 'Deleted',
    })
  };

  const displayModal = () => {
    Modal.confirm({
      centered: true,
      width: 500,
      title: 'Careful!',
      icon: '',
      content: <>
        <p>You are about to <span style={{ fontWeight: '600', color: '#d85c5c' }}>delete</span> all of the selected notifications. You currently have <span style={{ fontWeight: '600' }}>{selectedRowKeys.length} notifications</span> selected.</p>
        <p>Are you sure you wish to proceed?</p>
      </>,
      onOk: () => deleteNotifications(selectedRowKeys, filters, dispatchSelectedFilters, setSelectedRowKeys),
      okText: 'Delete all notifications',
      cancelText: 'Go back to notifications',
    });
  }

  return (
    <>
      <div className={styles.actionButtonsCheckbox}>
        {selectedRowKeys.length > 0 ?
          <>
            <Button ghost onClick={() => displayModal()} className={styles.deleteSelected}>
              Delete
            </Button>
          </>
          : ""
        }
      </div>
      <AntTable
        rowSelection={rowSelection}
        columns={tableColumns}
        dataSource={notifications}
        loading={loadingNotifications}
        pagination={false}
        rowKey={({ id }) => id}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalRows}
              current={currentPage}
              pageSize={pageSize}
              showSizeChanger
              pageSizeOptions={["10", "20", "30", "50", "100"]}
              onChange={onPaginationChange}
              onShowSizeChange={onPaginationChange}
            />
          );
        }}
        scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
      />
    </>
  );
};
