import React, { useEffect, useState, useCallback } from "react";
import { Row, Input, Button } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styles from "../Preview.module.css";
import { openSuccessNotification } from "../../../utils/notifications";

export const TrackingInfo = ({ campaign, trackingUrls }) => {
  const [tracker, setTracker] = useState("");
  const placeholder = useCallback(() => {
    if (campaign.pricing && campaign.pricing.price) {
      switch (campaign.general.conversion_tracking_type) {
        case "Default":
          return trackingUrls.manual.general;
        case "Voluum":
          return trackingUrls.manual.voluum;
        case "Affise":
          return trackingUrls.manual.affise;
        case "Binom":
          return trackingUrls.manual.binom;
        default:
          return null;
      }
    } else {
      switch (campaign.general && campaign.general.conversion_tracking_type) {
        case "Default":
          return trackingUrls.auto.general;
        case "Voluum":
          return trackingUrls.auto.voluum;
        case "Affise":
          return trackingUrls.auto.affise;
        case "Binom":
          return trackingUrls.auto.binom;
        default:
          return null;
      }
    }
  }, [trackingUrls, campaign]);

  useEffect(() => {
    const getS2sUrl = () => {
      let selectedTracker = placeholder(trackingUrls);
      if (campaign.general && campaign.general.conversion_tracking_value) {
        selectedTracker = selectedTracker.replace(
          "{payout}",
          campaign.general.conversion_tracking_value
        );
        selectedTracker = selectedTracker.replace(
          "{sum}",
          campaign.general.conversion_tracking_value
        );
      }
      setTracker(selectedTracker);
    };
    getS2sUrl();
  }, [campaign, trackingUrls, placeholder]);

  const suffix = (
    <CopyToClipboard
      text={tracker}
      onCopy={() =>
        openSuccessNotification({
          message: "Successfully copied to clipboard!",
        })
      }
    >
      <Button type="primary">COPY</Button>
    </CopyToClipboard>
  );

  return (
    <>
      <h3 className={styles.generalInfoHeader}>Bid goal tracking</h3>
      <Row className={styles.trackingRow}>
        <p className={styles.trackingParagraph}>
          Copy this S2S Postback URL and paste to the relevant field in your
          tracker or CPA Network account.
        </p>
        <Input
          className={styles.trackingUrl}
          suffix={suffix}
          value={tracker}
          readOnly
        />
        <p className={styles.trackingParagraph}>
          Do not forget to change{" "}
          <span className={styles.trackingParamSpan}>
            params in curly brackets
          </span>{" "}
          to proper parameter names.
        </p>
      </Row>
    </>
  );
};
