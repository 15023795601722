import React from "react";
import { DatePicker } from "antd";
import styles from '../ActivityLog.module.css';
import { Select, Row, Col, Button } from "antd";
import { SwapOutlined } from '@ant-design/icons';
import { generateCampaigns, generatePartners, generateUsers } from "../utils/filterOptions";

const { Option } = Select;

export const ActivityLogFilters = ({ campaignsList, autocompletePartnerLoading, autocompleteCampaignLoading, usersLoading, setPartnerSearchTerm, setCampaignSearchTerm, partnersList, usersList, filters, setFilters, submit }) => {
    return(
    <>
    <div className={styles.filtersContainer}>
        <h3>Filter</h3>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, { xs: 8, sm: 12, md: 12, lg: 16 }]}>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <span className={styles.filterSpan}>Campaign action</span>
                <Select
                    size="medium"
                    mode="multiple"
                    className={styles.selectFilter}
                    placeholder="All Actions"
                    allowClear
                    onChange={(value) => {
                    setFilters({ ...filters, campaignAction: value });
                    }}
                    value={filters.campaignAction}
                >
                    <Option value="Approved">Approved</Option>
                    <Option value="Rejected">Rejected</Option>
                    <Option value="Suspended">Suspended</Option>
                </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <span className={styles.filterSpan}>Campaign name</span>
                <Select
                    mode="multiple"
                    onChange={value => setFilters({ ...filters, campaignsList: value })}
                    style={{ width: "100%" }}
                    placeholder="Select campaign"
                    onSearch={setCampaignSearchTerm}
                    value={filters.campaignsList}
                    loading={autocompleteCampaignLoading}
                    filterOption={false}
                    allowClear>
                    {generateCampaigns(campaignsList || [])}
                </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <span className={styles.filterSpan}>Campaign type</span>
                <Select
                    size="medium"
                    mode="multiple"
                    className={styles.selectFilter}
                    placeholder="All Types"
                    allowClear
                    onChange={(value) => {
                    setFilters({ ...filters, campaignType: value });
                    }}
                    value={filters.campaignType}
                >
                    <Option value="Push">Push</Option>
                    <Option value="Premium">Premium Push</Option>
                    <Option value="Native">Native</Option>
                    <Option value="Contextual Ad">Contextual Ad</Option>
                    <Option value="Smartlink">Smartlink</Option>
                </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <span className={styles.filterSpan}>Partner name</span>
                <Select
                    mode="multiple"
                    onChange={value => setFilters({ ...filters, partnersList: value })}
                    style={{ width: "100%" }}
                    placeholder="Select partner"
                    onSearch={setPartnerSearchTerm}
                    value={filters.partnersList}
                    loading={autocompletePartnerLoading}
                    filterOption={false}
                    allowClear>
                    {generatePartners(partnersList || [])}
                </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <span className={styles.filterSpan}>User</span>
                <Select
                    mode="multiple"
                    onChange={value => setFilters({ ...filters, usersList: value })}
                    loading={usersLoading}
                    style={{ width: "100%" }}
                    placeholder="Select user"
                    filterOption={(inputValue, option) => option.children.toLowerCase().includes(inputValue.toLowerCase())}
                    value={filters.user}
                    allowClear>
                    {generateUsers(usersList || [])}
                </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <span className={styles.filterSpan}>Action date</span>
                <DatePicker 
                    onChange={(value) => {
                        setFilters({ ...filters, date: value });
                        }} 
                    className={styles.selectFilter}
                />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <Button
                className={styles.updateButton}
                type="primary"
                size="medium"
                onClick={() => submit(filters)}
                icon={<SwapOutlined />}
                >
                Update
                </Button>
            </Col>
        </Row>
    </div>
    </>
    );
};