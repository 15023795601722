import React from "react";
import { Button, Select, Row, Col } from "antd";
import { SwapOutlined } from '@ant-design/icons';
import { generateCampagins, generatePartners } from '../utils/filterOptions';
import styles from "../AllCampaigns.module.css";

const { Option } = Select;

export const AllCampaignsFilters = ({
  campaignsList,
  partnersList,
  autocompleteAllCampaignsLoading,
  autocompletePartnersLoading,
  setSearchCampaign,
  setSearchPartner,
  filters,
  setFilters,
  submit
}) => {
  return (
    <>
      <div className={styles.filtersContainer}>
        <h3>Filter</h3>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 8]} style={{ alignItems: 'flex-end' }}>
          <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Campaign status</span>
            <Select
              size="medium"
              mode="multiple"
              className={styles.selectFilter}
              placeholder="All Statuses"
              allowClear
              onChange={(value) => {
                setFilters({ ...filters, status: value });
              }}
              value={filters.status}
            >
              <Option value="active">Active</Option>
              <Option value="disabled">Disabled</Option>
              <Option value="paused">Paused</Option>
              <Option value="pending">Pending</Option>
              <Option value="suspended">Suspended</Option>
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Campaign name / ID</span>
            <Select
              mode="multiple"
              onChange={(value) => setFilters({ ...filters, campaigns: value })}
              style={{ width: '100%' }}
              placeholder="Select campaigns"
              onSearch={setSearchCampaign}
              value={filters.campaigns}
              loading={autocompleteAllCampaignsLoading}
              filterOption={false}
              allowClear
            >
              {generateCampagins(campaignsList || [])}
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Campaign type</span>
            <Select
              mode="multiple"
              size="medium"
              className={styles.selectFilter}
              placeholder="All Formats"
              allowClear
              onChange={(value) => {
                setFilters({ ...filters, type: value });
              }}
              filterOption={(inputValue, option) => option.children.toLowerCase().includes(inputValue.toLowerCase())}
              value={filters.type}
            >
              <Option value="Push">Push</Option>
              <Option value="Premium">Premium Push</Option>
              <Option value="Contextual Ad">Contextual Ad</Option>
              <Option value="Native">Native</Option>
              <Option value="Smartlink">Smartlink</Option>
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Partner name / Company</span>
            <Select
              mode="multiple"
              onChange={value => setFilters({ ...filters, partner_id: value })}
              style={{ width: "100%" }}
              placeholder="Select partners/companies"
              onSearch={setSearchPartner}
              value={filters.partner_id}
              loading={autocompletePartnersLoading}
              filterOption={false}
              allowClear
            >
              {generatePartners(partnersList || [])}
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Button
              className={styles.updateButton}
              type="primary"
              size="medium"
              onClick={() => submit(filters)}
              icon={<SwapOutlined />}
            >
              Update
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};
