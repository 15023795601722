import styles from "../ActivityLog.module.css";
import moment from "moment";
import { CampaignTypeCol } from '../components/CampaignTypeCol';
import { CampaignActionCol } from '../components/CampaignActionCol';
import { CreativesStatusCol } from "../components/CreativesStatusCol";

export const allColumns = [
      {
        title: "Campaign action",
        dataIndex: "campaign_action",
        align: "center",
        className: styles.statusColumn,
        render: status => {
          return <CampaignActionCol status={status} />;
        },
      },
      {
        title: "User ID",
        dataIndex: "user_id",
      },
      {
        title: "User",
        dataIndex: "user",
      },
      {
        title: "Campaign ID",
        dataIndex: "campaign_id",
      },
      {
        title: "Campaign name",
        dataIndex: "campaign_name",
      },
      {
        title: "Campaign type",
        dataIndex: 'campaign_type',
        tooltip: "Ad format (Push, Premium Push, Native, Contextual ad, Smartlink)",
        align: "center",
        render: (campaignType) => {
          return <CampaignTypeCol campaignType={campaignType} />;
        }
      },
      {
        title: "Partner id",
        dataIndex: "partner_id",
      },
      {
        title: "Partner name",
        dataIndex: "partner_name",
      },
      {
        title: "Creatives approved",
        dataIndex: "approved_creatives",
        align: "center",
        render: approved_creatives => {
          return <CreativesStatusCol creatives={approved_creatives} />;
        },
      },
      {
        title: "Creatives rejected",
        dataIndex: "rejected_creatives",
        align: "center",
        render: rejected_creatives => {
          return <CreativesStatusCol creatives={rejected_creatives} />;
        },
      },
      {
        title: "Date",
        dataIndex: "date",
        render: date =>
          date && moment(date).format("DD MMM YYYY hh:mm:ss"),
      }
];