import React, { useState } from "react";
import { Layout } from "antd";
import Media from "react-media";

import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import { SwitchRoutes } from "../routes/SwitchRoutes";
import { getAllowedRoutes } from "../routes";
import { useLocation } from "react-router-dom";
import { isHeaderAndSidebarHidden } from "./utils/hiddenSidebar";
import { UserContextProvider } from "../contexts/userContext";

export const AppLayout = () => {
  const location = useLocation();
  const isSidebarVisible = !isHeaderAndSidebarHidden(location);

  const wasCollapsed = localStorage.getItem("collapse");
  const [collapsed, setCollapsed] = useState(
    wasCollapsed ? wasCollapsed === "true" : window.innerWidth < 1400
  );

  return (
    <>
      <Layout style={{ minHeight: "100vh", maxHeight: "100vh", zIndex: "2" }}>
        {isSidebarVisible && (
          <Media queries={{ small: { minWidth: 599 } }}>
            {(matches) => (
              <Sidebar
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                isSmallScreen={!matches.small}
                selectedKey={location.pathname}
              />
            )}
          </Media>
        )}
        <Layout>
          <UserContextProvider>
            {isSidebarVisible && (
              <Media queries={{ small: { minWidth: 599 } }}>
                {(matches) => (
                  <Header
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    isSmallScreen={!matches.small}
                  />
                )}
              </Media>
            )}
            <Layout.Content
              style={{ margin: isSidebarVisible ? "20px 20px 20px 20px" : 0 }}
            >
              <div
                style={{
                  padding: isSidebarVisible ? 24 : 0,
                  background: "#fff",
                  minHeight: 360,
                }}
              >
                <SwitchRoutes routes={getAllowedRoutes()} />
              </div>
            </Layout.Content>
          </UserContextProvider>
        </Layout>
      </Layout>
    </>
  );
};
