import React from "react";

import styles from "../Transactions.module.css";

export const StatusTableCol = ({ status }) => {
  return (
    <div className={`${styles.status} ${styles["status" + status]}`}>
      {status === 'init' ? 'Initialized' : status}
    </div>
  );
};
