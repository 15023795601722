import React from "react";
import { Table as AntTable, Pagination } from "antd";
import styles from "../Accounts.module.css";

export const Table = ({
  tableData,
  totalRows,
  currentPage,
  pageSize,
  loading,
  onPaginationChange,
  tableColumns,
}) => {
  return (
    <AntTable
      className={styles.dataTable}
      columns={tableColumns}
      dataSource={tableData}
      loading={loading}
      pagination={false}
      rowKey={({ id }) => id}
      footer={() => {
        return (
          <Pagination
            className="ant-table-pagination ant-table-pagination-right"
            total={totalRows}
            current={currentPage}
            pageSize={pageSize}
            showSizeChanger
            pageSizeOptions={["10", "20", "30", "50", "100"]}
            onChange={onPaginationChange}
            onShowSizeChange={onPaginationChange}
          />
        );
      }}
      scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
    />
  );
};
