import React from "react";

import styles from "../Dashboard.module.css";

export const StatusTableCol = ({ status }) => {
  return (
    <div className={`${styles.status} ${styles["status" + status]}`}>
      {status}
    </div>
  );
};
