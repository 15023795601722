import React from 'react';
import { Row, Table } from 'antd';
import styles from '../Preview.module.css';
import { CampaignTypeCol } from '../../utils/CampaignTypeCol';

export const CreativesInfo = ({ campaignCreatives }) => {

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return <span className={`${styles.status} ${styles["status" + status]}`}>{status}</span>
      }
    },
    {
      title: 'Creative Name',
      dataIndex: 'title',
      key: 'title',
      render: (title) => {
        return <p style={{ marginBottom: 0 }}>{title && title[Object.keys(title)[0]]}</p>;
      }
    },
    {
      title: 'Creative Type',
      dataIndex: 'type',
      key: 'type',
      align: "center",
      render: (campaignType) => {
        return <CampaignTypeCol campaignType={campaignType} />;
      }
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      align: "center",
      render: (image) => {
        return (
          <div>
            {image && Object.keys(image)[0] && (
              <img style={{ width: 89, height: 50, objectFit: 'cover' }} src={image[Object.keys(image)[0]]} alt="creative" />
            )}
          </div>
        );
      }
    }
  ];

  return (
    <>
      <h3>Creatives</h3>
      <Row style={{ marginBottom: '30px', width: '100%' }}>
        <Table
          rowKey={(record) => record.id + record.gen_id}
          columns={columns}
          dataSource={campaignCreatives}
          pagination={false}
        />
      </Row>
    </>
  );
};
