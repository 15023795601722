import React from "react";
import { Table as AntTable, Pagination, Button, Modal, notification } from "antd";
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';

import styles from "../Dashboard.module.css";

import { campaignsService } from "../../../services/campaigns";
import { NestedTable } from "./NestedTable";

export const Table = ({
  campaigns,
  totalRows,
  currentPage,
  pageSize,
  loadingCampaigns,
  onPaginationChange,
  tableColumns,
  filters,
  dispatchSelectedFilters,
  selectedRowKeys,
  setSelectedRowKeys
}) => {

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.status === 'disabled' || record.status === 'suspended',
    })
  };

  const approveSelected = async () => {
    notification.open({
      message: "Updating...",
      key: "approve",
      icon: <LoadingOutlined style={{ color: "#00cc66" }} />,
      duration: 0,
    });

    try {
      const res = await campaignsService.approveCampaignsAndCreatives(selectedRowKeys);
      if (res.success) {
        notification["success"]({
          message: "Successfully approved campaigns and creatives!",
          key: "approve",
        });
        setSelectedRowKeys([]);
        dispatchSelectedFilters({ type: "", payload: { filters } });
      } else {
        throw new Error();
      }
    } catch (e) {
      notification["error"]({
        message: "Error, failed to approve!",
        key: "approve",
      });
    }
  }

  const displayModal = () => {
    Modal.confirm({
      centered: true,
      width: 500,
      title: 'Careful!',
      icon: '',
      content: <>
        <p>You are about to <span style={{ fontWeight: '600', color: '#58A54F' }}>approve</span> all of the selected campaigns along with their creatives. You currently have <span style={{ fontWeight: '600' }}>{selectedRowKeys.length} campaigns</span> selected.</p>
        <p>Are you sure you wish to proceed?</p>
      </>,
      onOk: () => approveSelected(),
      okText: 'Approve all',
      cancelText: 'Go back',
    });
  }

  return (
    <>
      <div className={styles.actionButtonsCheckbox}>
        {selectedRowKeys.length > 0 ?
          <>
            <Button ghost onClick={() => displayModal()} className={styles.approveSelected} type="primary" icon={<CheckOutlined />}>
              Approve
            </Button>
          </>
          : ""
        }
      </div>
      <AntTable
        rowSelection={rowSelection}
        expandable={{
          expandedRowRender: (item) => {
            return (
              <div key={item.keyword}>
                <NestedTable
                  item={item}
                  filters={filters}
                  dispatchSelectedFilters={dispatchSelectedFilters}
                />
              </div>
            )
          },
          rowExpandable: item => item.status !== 'suspended'
        }}
        columns={tableColumns}
        dataSource={campaigns}
        loading={loadingCampaigns}
        pagination={false}
        rowKey={({ id }) => id}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalRows}
              current={currentPage}
              pageSize={pageSize}
              showSizeChanger
              pageSizeOptions={["10", "20", "30", "50", "100"]}
              onChange={onPaginationChange}
              onShowSizeChange={onPaginationChange}
            />
          );
        }}
        scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
      />
    </>
  );
};
