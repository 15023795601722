import React, { useState, useEffect, useReducer } from "react";
import moment from "moment";

import { Table } from "./components/Table";
import styles from "./AccountStatements.module.css";
import { CampaignFilters } from "./components/CampaignsFilters";
import {
  allColumns,
  initialSelectedColumns,
  staticColumns,
} from "./utils/columns";
import { filtersReducer } from "./utils/reducers";
import { accountStatementsService } from "../../services/accountStatements";
import { useFetch } from "../../utils/hooks/useFetch";
import { useDebounce } from "../../utils/hooks/useDebounce";
import { openWarningNotification } from "../../utils/notifications";

export const AccountStatements = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedFilters, dispatchSelectedFilters] = useReducer(
    filtersReducer,
    {}
  );
  const [dateRange, setDateRange] = useState({
    from: moment().subtract(6, "days"),
    to: moment(),
  });
  const [filters, setFilters] = useState({});
  const [checkedColumns, setCheckedColumns] = useState(initialSelectedColumns);

  const [partnersList, setPartnersList] = useState([]);
  const [autocompletePartnersLoading, setAutocompletePartnersLoading] =
    useState(false);
  const [searchPartner, setSearchPartner] = useState("");
  const debouncePartner = useDebounce(searchPartner, 500);

  const loadMediaCampaigns = (cancelToken) =>
    accountStatementsService.getStatementsPaged({
      selectedFilters,
      checkedColumns,
      currentPage,
      pageSize,
      cancelToken,
    });

  const [
    {
      data: { results: campaigns, summary: sumOfData, count: totalRows },
      isFetching,
    },
    getMediaCampaigns,
  ] = useFetch(loadMediaCampaigns);

  const [selectedColumns, setSelectedColumns] = useState([
    ...staticColumns,
    ...allColumns,
  ]);

  useEffect(() => {
    dispatchSelectedFilters({
      payload: {
        filters: {},
        dateRange: { from: moment().subtract(30, "days"), to: moment() },
      },
    });
  }, []);

  useEffect(() => {
    const filteredColumns = allColumns.filter((item) => {
      return Object.keys(checkedColumns).some((key) => {
        return checkedColumns[key] && key === item.dataIndex;
      });
    });
    setSelectedColumns([...staticColumns, ...filteredColumns]);
  }, [selectedFilters, checkedColumns]);

  useEffect(() => {
    if (!selectedFilters.filters) return;
    if (selectedFilters.filters.partner_id) {
      getMediaCampaigns();
    } else {
      openWarningNotification({
        message: "Please select partner account to see account statements!",
      });
    }
  }, [currentPage, pageSize, getMediaCampaigns, selectedFilters]);

  useEffect(() => {
    const getPartners = async (searchPartner) => {
      setAutocompletePartnersLoading(true);
      try {
        const res = await accountStatementsService.getPartnersAutocomplete(
          searchPartner
        );
        let newPartners = [...res.data];
        setPartnersList(newPartners);
      } catch (e) {
        console.log(e);
      } finally {
        setAutocompletePartnersLoading(false);
      }
    };
    getPartners(debouncePartner);
  }, [debouncePartner]);

  return (
    <div className={styles.campaignsTable}>
      <h4>Account Statement</h4>
      <p>Follow your daily revenue</p>
      <CampaignFilters
        submit={(filters) => {
          dispatchSelectedFilters({
            type: "",
            payload: { filters, dateRange },
          });
        }}
        filters={filters}
        setFilters={setFilters}
        partnersList={partnersList}
        autocompletePartnersLoading={autocompletePartnersLoading}
        setSearchPartner={setSearchPartner}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      <Table
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        campaigns={campaigns}
        sumOfData={sumOfData}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loadingCampaigns={isFetching}
        getMediaCampaigns={getMediaCampaigns}
        onPaginationChange={(currentPage, pageSize) => {
          setCurrentPage(currentPage);
          setPageSize(pageSize);
        }}
        checkedColumns={checkedColumns}
        setCheckedColumns={setCheckedColumns}
        selectedFilters={selectedFilters}
        date
      />
    </div>
  );
};
