import React from "react";
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { notificationsService } from "../../../services/notifications";

export const deleteNotifications = async (ids, filters, dispatchSelectedFilters, setSelectedRowKeys) => {

    notification.open({
        message: "Updating...",
        key: "update",
        icon: <LoadingOutlined style={{ color: "#00cc66" }} />,
        duration: 0,
    });

    try {
        const res = await notificationsService.deleteMultipleNotifications(ids);
        if (res.success) {
            notification["success"]({
                message: "Update successful!",
                key: "update",
            });
            setSelectedRowKeys([]);
            dispatchSelectedFilters({ type: "", payload: { filters } });
        } else {
            throw new Error();
        }
    } catch (e) {
        notification["error"]({
            message: "Error, failed to update!",
            key: "update",
        });
    }
};
