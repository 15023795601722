import React from "react";

import styles from "../Accounts.module.css";

export const StatusTableCol = ({ status, title }) => {
  return (
    <div className={`${styles.status} ${styles["status" + status]}`}>
      {title}
    </div>
  );
};
