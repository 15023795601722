import React from "react";
import { Button, Select, Row, Col, DatePicker, Checkbox } from "antd";
import { SwapOutlined } from '@ant-design/icons';
import styles from "../NotificationsTable.module.css";
import moment from 'moment';

import { generateUsers, generatePartners } from '../utils/filterOptions';

const { Option } = Select;
const { RangePicker } = DatePicker;

export const NotificationsFilters = ({
  partnersList,
  creatorsList,
  creatorsLoading,
  autocompletePartnersLoading,
  setSearchPartner,
  filters,
  setFilters,
  submit,
  dateCreatedRange,
  setDateCreatedRange,
  dateEditedRange,
  setDateEditedRange,
  isDeleteIncluded,
  setIsDeleteIncluded
}) => {

  return (
    <>
      <div className={styles.filtersContainer}>
        <h3>Filters</h3>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ alignItems: 'flex-end', marginBottom: '.5rem' }}>
          <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Advertisers and companies</span>
            <Select
              mode="multiple"
              onChange={value => setFilters({ ...filters, partner_id: value })}
              style={{ width: "100%" }}
              placeholder="Select advertisers and companies"
              onSearch={setSearchPartner}
              value={filters.partner_id}
              loading={autocompletePartnersLoading}
              filterOption={false}
              allowClear
            >
              {generatePartners(partnersList || [])}
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Status</span>
            <Select
              size="medium"
              mode="multiple"
              className={styles.selectFilter}
              placeholder="Select status"
              allowClear
              onChange={(value) => {
                setFilters({ ...filters, action: value });
              }}
              value={filters.action}
            >
              <Option value="Created">Created</Option>
              <Option value="Edited">Edited</Option>
              <Option value="Deleted">Deleted</Option>
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Date created</span>
            <RangePicker
              format="DD.MM.YYYY"
              onChange={(dates) => {
                if (dates) {
                  let dateCreated = { from: dates[0], to: dates[1] };
                  setDateCreatedRange(dateCreated);
                } else {
                  let dateCreated = { from: null, to: null };
                  setDateCreatedRange(dateCreated);
                }
              }}
              defaultValue={[moment(dateCreatedRange.from), moment(dateCreatedRange.to)]}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Date edited</span>
            <RangePicker
              format="DD.MM.YYYY"
              onChange={(dates) => {
                if (dates) {
                  let dateEdited = { from: dates[0], to: dates[1] };
                  setDateEditedRange(dateEdited);
                } else {
                  let dateEdited = { from: null, to: null };
                  setDateEditedRange(dateEdited);
                }
              }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ alignItems: 'flex-end' }}>
          <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Creator</span>
            <Select
              mode="multiple"
              onChange={value => setFilters({ ...filters, user_id: value })}
              style={{ width: "100%" }}
              placeholder="Select creator"
              onSearch={setSearchPartner}
              value={filters.user_id}
              loading={creatorsLoading}
              filterOption={false}
              allowClear
            >
              {generateUsers(creatorsList || [])}
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Notification type</span>
            <Select
              size="medium"
              mode="multiple"
              className={styles.selectFilter}
              placeholder="Select notification type"
              allowClear
              onChange={(value) => {
                setFilters({ ...filters, type: value });
              }}
              value={filters.type}
            >
              <Option value="Broadcast">Broadcast</Option>
              <Option value="Notification Bar">Notification Bar</Option>
              <Option value="Interstitial">Interstitial</Option>
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={5} className={styles.isDeletedCheckbox}>
            <Checkbox checked={isDeleteIncluded}
              onChange={e =>
                setIsDeleteIncluded(e.target.checked)
              }>
              Include deleted
            </Checkbox>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Button
              className={styles.updateButton}
              type="primary"
              size="medium"
              onClick={() => submit(filters)}
              icon={<SwapOutlined />}
            >
              Update
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};
