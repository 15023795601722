import { callApi } from "./api";

class AuthService {
  user = null;

  async loadPermissions() {
    if (this.user) {
      return this.user;
    } else {
      const data = await callApi({ url: "/api/accounts/" });
      this.user = data;
      return this.user;
    }
  }

  isSuperuser() {
    if (this.user) {
      return this.user[0].is_superuser;
    }
    return false;
  }

  logout() {
    window.location.href = "/logout";
  }
}

export const auth = new AuthService();
