import React, { useState } from "react";

import { Button, message, Space, Tooltip } from "antd";

import { LoginOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";

import styles from "../Accounts.module.css";
import { accountsService } from "../../../services/accounts";
import { openErrorNotification } from "../../../utils/notifications";

export const ActionsTableCol = ({
  id,
  cns_cmp_id,
  status,
  temporaryStripeBlock,
  permanentStripeBlock,
  filters,
  dispatchSelectedFilters,
  updateCashedData,
  cashedTableData,
}) => {
  const [loginLoading, setLoginLoading] = useState(false);
  const [actionsLoading, setActionsLoading] = useState(false);

  const updateAccount = async (data) => {
    setActionsLoading(true);
    message.loading({ content: "Updating...", key: id, duration: 0 });
    try {
      const res = await accountsService.updateAccount(
        id,
        data,
        cashedTableData
      );
      if (res.success) {
        message.success({ content: res.message, key: id });
        updateCashedData(id, data);
      } else {
        message.error({ content: res.message, key: id });
      }
    } catch (e) {
      message.error({
        content: "Unable to update account. Please contact support.",
        key: id,
      });
    } finally {
      setActionsLoading(false);
    }
  };

  const login = async () => {
    setLoginLoading(true);
    try {
      const res = await accountsService.login(cns_cmp_id);
      if (res.message === "Success") {
        window.open(res.data);
      } else {
        openErrorNotification({ message: res.message });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoginLoading(false);
    }
  };

  return (
    <>
      <Space>
        <>
          <Tooltip title="Login">
            <Button
              onClick={login}
              style={{ color: "#737373" }}
              icon={<LoginOutlined />}
              loading={loginLoading}
            />
          </Tooltip>
          {!status ? (
            <Tooltip title="Activate">
              <Button
                onClick={() => updateAccount({ is_active: true })}
                className={styles.approve}
                disabled={actionsLoading}
                icon={
                  <Icon
                    component={() => (
                      <img
                        className={styles.activateCampaignIcon}
                        src="/images/activate.png"
                        alt=""
                      />
                    )}
                  />
                }
              />
            </Tooltip>
          ) : (
            <Tooltip title="Suspend">
              <Button
                onClick={() => updateAccount({ is_active: false })}
                className={styles.suspend}
                disabled={actionsLoading}
                icon={
                  <Icon
                    component={() => (
                      <img
                        className={styles.suspendCampaignIcon}
                        src="/images/suspend.png"
                        alt=""
                      />
                    )}
                  />
                }
              />
            </Tooltip>
          )}
          {!temporaryStripeBlock ? (
            <Tooltip title="Temporary Stripe Block">
              <Button
                onClick={() => updateAccount({ temporary_stripe_block: true })}
                className={styles.suspend}
                disabled={actionsLoading}
              >
                TSB
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title="Temporary Stripe Unblock">
              <Button
                onClick={() => updateAccount({ temporary_stripe_block: false })}
                className={styles.suspend}
                disabled={actionsLoading}
              >
                TSU
              </Button>
            </Tooltip>
          )}

          {!permanentStripeBlock ? (
            <Tooltip title="Permanent Stripe Block">
              <Button
                onClick={() => updateAccount({ permanent_stripe_block: true })}
                className={styles.suspend}
                disabled={actionsLoading}
              >
                PSB
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title="Permanent Stripe Unblock">
              <Button
                onClick={() => updateAccount({ permanent_stripe_block: false })}
                className={styles.suspend}
                disabled={actionsLoading}
              >
                PSB
              </Button>
            </Tooltip>
          )}
        </>
      </Space>
    </>
  );
};
