import moment from "moment";
import { StatusTableCol } from "../components/StatusTableCol";

export const allColumns = [
  {
    title: "Username",
    dataIndex: "username",
  },
  {
    title: "Partner name",
    dataIndex: "partner_name",
  },
  {
    title: "Status",
    dataIndex: "is_active",
    render: (value) => (
      <StatusTableCol
        status={value ? "active" : "suspended"}
        title={value ? "active" : "suspended"}
      />
    ),
  },
  {
    title: "Company ID",
    dataIndex: "cns_cmp_id",
  },
  {
    title: "Company Name",
    dataIndex: "cns_cmp_name",
  },
  {
    title: "Last Login",
    dataIndex: "last_login",
    render: (last_login) =>
      last_login && moment(last_login).format("DD MMM YYYY hh:mm:ss"),
  },
  {
    title: "Stripe Block",
    children: [
      {
        title: "Temporary",
        dataIndex: "temporary_stripe_block",
        render: (value) => (
          <StatusTableCol
            status={!value ? "active" : "suspended"}
            title={!value ? "Active" : "Blocked"}
          />
        ),
        // render: (last_login) =>
        //   last_login && moment(last_login).format("DD MMM YYYY hh:mm:ss"),
      },
      {
        title: "Permanently",
        dataIndex: "permanent_stripe_block",
        render: (value) => (
          <StatusTableCol
            status={!value ? "active" : "suspended"}
            title={!value ? "Active" : "Blocked"}
          />
        ),
        // render: (last_login) =>
        //   last_login && moment(last_login).format("DD MMM YYYY hh:mm:ss"),
      },
    ],
  },
];
