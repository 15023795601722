import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Form, Input, Tooltip, Select, Radio, DatePicker, Spin, Modal } from 'antd';
import { useDebounce } from "../../utils/hooks/useDebounce";
import { InfoCircleOutlined, LinkOutlined } from "@ant-design/icons";
import { notificationsService } from "../../services/notifications";
import styles from "./Notification.module.css";
import moment from "moment";
import { openErrorNotification, openSuccessNotification } from "../../utils/notifications";

const Option = Select.Option;
const { TextArea } = Input;

export const Notification = () => {
    const history = useNavigate();
    const [loading, setLoading] = useState(false);

    const [notificationType, setNotificationType] = useState('');

    const [partnerSearchTerm, setPartnerSearchTerm] = useState("");
    const [partnerAutocompleteLoading, setPartnerAutocompleteLoading] = useState(false);
    const [partnersList, setPartnersList] = useState([]);
    const debouncePartner = useDebounce(partnerSearchTerm, 300);

    const [isSelectedAllUsers, setIsSelectedAllUsers] = useState(false);

    const [form] = Form.useForm();

    const notificationIconsList = [
        {
            id: 1,
            value: '/resources/notification_icons/awesome-lightbulb.png',
            label: 'Platform news'
        },
        {
            id: 2,
            value: '/resources/notification_icons/icon-awesome-newspaper2.png',
            label: 'Newsletter'
        },
        {
            id: 3,
            value: '/resources/notification_icons/metro-dollar2.png',
            label: 'Billing'
        },
    ];

    const buttonNameList = [
        {
            id: 1,
            value: 'Okay'
        },
        {
            id: 2,
            value: 'I Understand'
        }
    ];

    const generateIconsList = arr => {
        return arr.map(option => (
            <Option key={option.id} value={option.value}>
                <div className={styles.iconContainer}><img src={option.value} alt="" /></div>
                <span>{option.label}</span>
            </Option>
        ));
    }

    const generatePartners = arr => {
        if (arr[0] && arr[0].id !== 'all') arr.unshift({ id: 'all', company_id: 'all', partner_name: 'All Users' });
        return arr.map(option => (
            // Slao se company_id value={option.company_id} 
            <Option key={option.id} value={option.id} disabled={(isSelectedAllUsers) ? true : false}>
                {option.partner_name}
            </Option>
        ));
    };

    const onRbtnChange = (e) => {
        setNotificationType(e.target.value);
    }

    useEffect(() => {
        const getPartners = async partnerSearchTerm => {
            setPartnerAutocompleteLoading(true);
            try {
                const res = await notificationsService.getPartnersAutocomplete(partnerSearchTerm);
                if (res.data) {
                    setPartnersList(res.data);
                }
            } catch (e) {
                console.log(e);
            } finally {
                setPartnerAutocompleteLoading(false);
            }
        };
        getPartners(debouncePartner);
    }, [debouncePartner]);

    const handleSubmit = async values => {
        let formValues = { ...values };
        if (values.advertisers[0] && values.advertisers[0] === 'all') {
            formValues = { ...formValues, advertisers: [], all_advertisers: true };
        } else {
            formValues = { ...formValues, all_advertisers: false };
        }
        const formatedValues = { ...formValues, end_date: !formValues.end_date ? null : moment(formValues.end_date).format("YYYY-MM-DD HH:mm:ss") }
        setLoading(true);
        try {
            const res = await notificationsService.createNotification(formatedValues);
            if (res.success) {
                openSuccessNotification({ message: res.message });
                history("/notifications/all-notifications");
            } else {
                openErrorNotification({ message: res.message });
            }
        } catch (e) {
            openErrorNotification({ message: e.message });
        } finally {
            setLoading(false);
        }
    };

    const [descriptionLinkForm] = Form.useForm();

    const handleSubmitDescriptionLink = () => {
        let linkText = descriptionLinkForm.getFieldValue('link_text');
        let linkUrl = descriptionLinkForm.getFieldValue('link_url');
        let oldContent = form.getFieldValue('description') === undefined ? "" : form.getFieldValue('description');
        form.setFieldsValue({ description: oldContent + "[" + linkText + "](" + linkUrl + ")" });
        descriptionLinkForm.resetFields();
    }

    const openLinkModal = () => {
        Modal.confirm({
            wrapClassName: styles.descriptionLinkModal,
            title: 'Add link to your description',
            icon: null,
            content: <>
                <p>Please input link URL and text.</p>
                <Form
                    form={descriptionLinkForm}
                    layout="vertical"
                    name="descriptionLinkForm"
                >
                    <Form.Item
                        name="link_text"
                        label="Link text"
                    >
                        <Input placeholder="Enter link text" />
                    </Form.Item>
                    <Form.Item
                        name="link_url"
                        label="Link URL"
                    >
                        <Input placeholder="Enter link url" />
                    </Form.Item>
                </Form>
            </>,
            okText: 'Done',
            onOk: handleSubmitDescriptionLink,
            cancelText: 'Cancel',
            onCancel: () => descriptionLinkForm.resetFields()
        });
    };

    return (
        <Spin spinning={loading}>
            <div className={styles.pageTitle}>
                <h4>New Notification</h4>
                <p>Set up your notification below.</p>
            </div>
            <div className={styles.formContainer}>
                <Form
                    form={form}
                    layout="vertical"
                    name="notificationForm"
                    onFinish={handleSubmit}
                >
                    <Form.Item
                        name="title"
                        label="Notification title"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter notification title!'
                            }]}
                    >
                        <Input
                            placeholder="Enter notification title"
                            showCount
                            maxLength={200}
                        />
                    </Form.Item>
                    <div className={styles.descriptionContainer}>
                        <Form.Item
                            name="description"
                            label={<div className={styles.info}>
                                <span>Notification description</span>
                                <Tooltip title="Description may contain URL, which will open in a new tab.">
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </div>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter notification description!'
                                }]}
                        >
                            <TextArea
                                rows={6}
                                placeholder="Enter notification description"
                            />
                        </Form.Item>
                        <Button
                            icon={<LinkOutlined />}
                            className={styles.descriptionLink}
                            onClick={openLinkModal}
                        >
                            Add link to description
                        </Button>
                    </div>
                    <Form.Item
                        className={styles.iconsListWrapper}
                        label="Notification icon"
                        name="icon"
                    >
                        <Select
                        >
                            {generateIconsList(notificationIconsList)}
                        </Select>
                    </Form.Item>
                    <p style={{ marginBottom: "24px" }}>Select a new icon from the predetermined combinations, or you can add your own. In case you add a new icon, make sure to add activity related to it once you’re prompted below.</p>
                    <Form.Item
                        label="Notification type"
                        rules={[
                            {
                                required: true,
                                message: 'Please choose notification type!'
                            }
                        ]}
                        name="type"
                    >
                        <Radio.Group
                            className={styles.notificationType}
                            onChange={onRbtnChange}
                        >
                            <Radio.Button value="Broadcast">Broadcast</Radio.Button>
                            <Radio.Button value="Notification Bar">Notification Bar</Radio.Button>
                            <Radio.Button value="Interstitial">Interstitial</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    {notificationType === "Interstitial" ?
                        <>
                            <p className={styles.warningInterstitial}>Adding this notification will delete all of previous interstitial notifications!</p>
                            <Form.Item
                                label="Interstitial button name"
                                name="interstitial_button_name"
                                tooltip={{
                                    title: 'Chosen value will appear on button which will be used for reading interstitial notification.',
                                    icon: <InfoCircleOutlined />
                                }}
                                initialValue={buttonNameList[0].value}
                            >
                                <Select defaultValue={buttonNameList[0]}>
                                    {buttonNameList.map((el) => {
                                        return (
                                            <Option key={el.id} value={el.value}>
                                                {el.value}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </> : null
                    }
                    <Form.Item
                        label="End date"
                        rules={[
                            {
                                required: notificationType === "Broadcast" ? true : false,
                                message: 'Please select notification icon!'
                            }
                        ]}
                        name="end_date"
                    >
                        <DatePicker style={{ width: "230px" }} showTime placeholder="Select end date" />
                    </Form.Item>
                    <Form.Item
                        name="advertisers"
                        label="Advertisers and companies"
                        // label={<div className={styles.info}>
                        //     <span>Advertisers and companies</span>
                        //     <Tooltip title="If you make no selection, the notification will be sent to all advertisers.">
                        //         <InfoCircleOutlined />
                        //     </Tooltip>
                        // </div>}
                        rules={[
                            {
                                required: true,
                                message: 'Please select at least one advertiser or company!'
                            }
                        ]}
                    >
                        <Select
                            mode="multiple"
                            loading={partnerAutocompleteLoading}
                            filterOption={false}
                            style={{ width: "100%" }}
                            className={isSelectedAllUsers ? styles.showClearOnAllUsers : ""}
                            placeholder="Select advertiser"
                            onSearch={setPartnerSearchTerm}
                            onChange={(value) => {
                                if (value.includes('all')) {
                                    setIsSelectedAllUsers(true);
                                    form.setFieldsValue({ advertisers: ['all'] });
                                } else {
                                    setIsSelectedAllUsers(false);
                                }
                            }}
                            allowClear
                        >
                            {generatePartners(partnersList || [])}
                        </Select>
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Spin>
    );
};
