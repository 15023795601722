import React, { useEffect, useState } from "react";
import { Drawer, Space, Spin } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import { reportsService } from "../../services/reports";
import { campaignsService } from "../../services/campaigns";
import { trackingService } from "../../services/tracking";

import styles from "./Preview.module.css";
import { GeneralInfo } from "./components/GeneralInfo";
import { TargetingInfo } from "./components/TargetingInfo";
import { TrafficInfo } from "./components/TrafficInfo";
import { CreativesInfo } from "./components/CreativesInfo";
import { TrackingInfo } from "./components/TrackingInfo";

export const CampaignPreview = ({ campaignId, setPreviewVisible, previewVisible }) => {
    const [campaignInfo, setCampaignInfo] = useState({});
    const [loadingDetails, setLoadingDetails] = useState(true);

    const [countriesAll, setCountriesAll] = useState([]);
    const [osAll, setOsAll] = useState([]);
    const [browserAll, setBrowserAll] = useState([]);
    const [typeDeviceAll, setTypeDeviceAll] = useState([]);
    const [manufacturerAll, setManufacturerAll] = useState([]);
    const [categoriesAll, setCategoriesAll] = useState([]);
    const [trackingUrls, setTrackingUrls] = useState({});

    useEffect(() => {
        const getAndParseTargetingOptions = async () => {
            try {
                const [country, os, browser, type, manufacturer, category, trackingUrls] = await Promise.all([
                    reportsService.getAllCountries(),
                    reportsService.getAllOS(),
                    reportsService.getAllBrowsers(),
                    campaignsService.getAllDeviceTypes(),
                    campaignsService.getAllDeviceManufacturers(),
                    reportsService.getIABCategories(),
                    trackingService.getUrl()
                ]);
                if (country.success) {
                    let countriesArray = country.data.map((el) => {
                        return { label: el.name, value: el.code };
                    });
                    setCountriesAll(countriesArray);
                }
                if (os.success) {
                    setOsAll(os.data);
                }
                if (browser.success) {
                    setBrowserAll(browser.data);
                }
                if (type.success) {
                    let devicesList = type.data.items.map((el) => {
                        return { label: el.name, value: el.id };
                    });
                    setTypeDeviceAll(devicesList);
                }
                if (manufacturer.success) {
                    let devicesList = manufacturer.data.map((el) => {
                        return { label: el.name.charAt(0).toUpperCase() + el.name.slice(1).toLowerCase(), value: el.id };
                    });
                    setManufacturerAll(devicesList);
                }
                if (category.success) {
                    setCategoriesAll(category.data.items);
                }
                if (trackingUrls.success) {
                    setTrackingUrls(trackingUrls.data);
                }
            } catch (e) {
                console.log(e);
            } finally {
            }
        };
        if (previewVisible) getAndParseTargetingOptions();
    }, [previewVisible]);

    useEffect(() => {
        const getCampaignDetails = async () => {
            setLoadingDetails(true);
            try {
                const res = await campaignsService.getCampaignDetails(
                    campaignId
                );
                setCampaignInfo(res.data)
            } catch (e) {
                console.log(e);
            } finally {
                setLoadingDetails(false);
            }
        };
        previewVisible && campaignId && getCampaignDetails();
    }, [previewVisible, campaignId]);

    if (!campaignInfo) {
        return null;
    }

    return <>
        <Drawer
            placement="right"
            className={styles.drawerContainer}
            visible={previewVisible}
            width="40%"
            title={
                <div>
                    <div className={styles.drawerTitle}>
                        <img alt="anouncement" src="images/bull-horn-announcer.svg" />
                        <h3>Campaign Preview</h3>
                    </div>
                </div>
            }
            footer={false}
            closable={false}
            onClose={() => setPreviewVisible(false)}
            extra={
                <Space>
                    <CloseCircleOutlined style={{ color: "#D1D1D1", transform: "scale(1.4)" }} onClick={() => setPreviewVisible(false)} />
                </Space>
            }
        >
            <div className={styles.campaignDetailsContainer}>
                {!loadingDetails ? (
                    <div>
                        <GeneralInfo campaignGeneral={campaignInfo.general} campaignPricing={campaignInfo.pricing} campaignLimit={campaignInfo.limit} />
                        <TargetingInfo campaignGeneral={campaignInfo.general} campaignTargeting={campaignInfo.targeting.targeting} countriesAll={countriesAll} osAll={osAll} browserAll={browserAll} typeDeviceAll={typeDeviceAll} manufacturerAll={manufacturerAll} campaignType={campaignInfo.general.type} />
                        <TrafficInfo campaignTargeting={campaignInfo.targeting.targeting} categoriesAll={categoriesAll} />
                        <CreativesInfo campaignCreatives={campaignInfo.creatives} />
                        {trackingUrls && <TrackingInfo campaign={campaignInfo} trackingUrls={trackingUrls} />}
                    </div>
                ) : (
                    <Spin spinning={loadingDetails} />
                )}
            </div>
        </Drawer>
    </>
}