
import React from 'react';
import { Select } from "antd";
const { Option } = Select;

export const generatePartners = (arr) => {
  return arr.map(option => (
    <Option key={option.id} value={option.id}>
      {option.partner_name}
    </Option>
  ));
};