import React from "react";
import styles from "../Layout.module.css";

export const Logo = ({ collapsed }) => {
  return (
    <div className={styles.logo}>
      <img src={collapsed ? "/images/logo-small.png" : "/images/logo.svg"} alt="Logo" />
      <span>Admin Dashboard</span>
    </div>
  );
};
