import { callApi } from "./api";

const handleOrdering = (sorting) => {
  const ordering = [];
  for (const key in sorting) {
    const val = sorting[key];
    if (val) {
      if (val === "ascend") {
        if (key === "hour") {
          ordering.push("-date");
        }
        ordering.push("-" + key);
      } else {
        if (key === "hour") {
          ordering.push("date");
        }
        ordering.push(key);
      }
    }
  }
  return ordering;
};
const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return "";
  }

  const query = Object.keys(queryParams)
    .filter((key) => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== "";
    })
    .map((key) => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return query ? (withoutQuestionMark ? query : `?${query}`) : "";
};

export const getOffsetLimitFromPagination = ({ currentPage, pageSize }) => ({
  limit: pageSize,
  offset: (currentPage - 1) * pageSize,
});

class ReportsService {
  async getAllCountries() {
    return await callApi({ url: "/api/countries/" });
  }

  async getAllOS() {
    return await callApi({ url: "/api/os/" });
  }

  async getAllBrowsers() {
    return await callApi({ url: "/api/browsers/" });
  }

  async getIABCategories() {
    return await callApi({ url: "/api/iab_categories/" });
  }

  async getTestReports() {
    return await callApi({
      url: "/api/reports/?date_from=2022-02-01&date_to=2022-03-10&company_id=13&columns=campaign_id,campaign_name,campaign_type,impressions,clicks,conversions,ctr,cpc,income,expense,coinis_revenue",
    });
  }

  async getPartnersAutocomplete(searchTerm) {
    return await callApi({
      url: `/api/partners/autocomplete/?search=${searchTerm}`,
    });
  }

  async getCampaignsAutocomplete(searchTerm) {
    return await callApi({
      url: `/api/campaigns/all/autocomplete/?search=${searchTerm}`,
    });
  }

  async getStatsPaged({
    selectedFilters,
    currentPage,
    pageSize,
    sorting,
    cancelToken,
    action,
  }) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    let colParameter = "";
    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += "," + key;
      }
    });
    const ordering = handleOrdering(sorting);
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      columns: colParameter,
      date_from: dateRange.from.format("YYYY-MM-DD"),
      date_to: dateRange.to.format("YYYY-MM-DD"),
      company_id: filters.advertiser && filters.advertiser.join(","),
      country: filters.countries && filters.countries.join(","),
      source: filters.source && filters.source.join(","),
      os: filters.os && filters.os.join(","),
      campaign_type: filters.campaignTypes && filters.campaignTypes.join(","),
      zone_id: filters.zone_id && filters.zone_id.join(","),
      campaign_name: filters.campaign_name && filters.campaign_name.join(","),
      campaign_id: filters.campaign_id && filters.campaign_id.join(","),
      browser: filters.browsers && filters.browsers.join(","),
      iab_name: filters.iabCategories && filters.iabCategories.join(","),
      ordering: ordering && ordering.join(","),
    });

    return await callApi({
      url: `/api/reports/${query}`,
      cancelToken,
    });
  }

  async getReports({
    selectedFilters,
    currentPage,
    pageSize,
    sorting,
    action,
  }) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    let colParameter = "";
    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += "," + key;
      }
    });
    const ordering = handleOrdering(sorting);
    const query = getQueryString(
      {
        ...getOffsetLimitFromPagination({ currentPage, pageSize }),
        columns: colParameter,
        date_from: dateRange.from.format("YYYY-MM-DD"),
        date_to: dateRange.to.format("YYYY-MM-DD"),
        company_id: filters.advertiser && filters.advertiser.join(","),
        country: filters.countries && filters.countries.join(","),
        os: filters.os && filters.os.join(","),
        campaign_type: filters.campaignTypes && filters.campaignTypes.join(","),
        campaign_name: filters.campaign_name && filters.campaign_name.join(","),
        campaign_id: filters.campaign_id && filters.campaign_id.join(","),
        zone_id: filters.zone_id && filters.zone_id.join(","),
        browser: filters.browsers && filters.browsers.join(","),
        iab_name: filters.iabCategories && filters.iabCategories.join(","),
        ordering: ordering && ordering.join(","),
      },
      true
    );

    window.location = `/api/reports/?resType=csv&${query}`;
  }
}

export const reportsService = new ReportsService();
