import React, { useEffect, useReducer, useState } from "react";
import { useFetch } from "../../utils/hooks/useFetch";
import { useDebounce } from "../../utils/hooks/useDebounce";
import { activityLogFiltersReducer } from "./utils/reducers";
import styles from "./ActivityLog.module.css";
import { ActivityLogFilters } from "./components/ActivityLogFilters";
import { activityLogService } from "../../services/activitylog";
import { allColumns } from "../activity-log/utils/columns";
import { Table } from "./components/Table";

export const ActivityLog = () => {
  const [filters, setFilters] = useState({});
  const [selectedFilters, dispatchSelectedFilters] = useReducer(
    activityLogFiltersReducer,
    {}
  );

  const [initialColumns, setInitialColumns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);  

  const [campaignsList, setCampaignsList] = useState([]);
  //const [campaignsLoading, setCampaignsLoading] = useState(false);
  const [partnersList, setPartnersList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);

  const [searchCampaignTerm, setCampaignSearchTerm] = useState("");
  const [autocompleteCampaignLoading, setAutocompleteCampaignLodaing] = useState(false);
  const debounceCampaign = useDebounce(searchCampaignTerm, 300);

  const [searchPartnerTerm, setPartnerSearchTerm] = useState("");
  const [autocompletePartnerLoading, setAutocompletePartnerLodaing] = useState(false);
  const debouncePartner = useDebounce(searchPartnerTerm, 300);

  const loadActivityLogs = cancelToken =>
    activityLogService.getActivityLogsPaged({
      selectedFilters,
      initialColumns,
      currentPage,
      pageSize,
      cancelToken,
    });

  useEffect(() => {
    const getPartners = async (searchPartnerTerm) => {
      setAutocompletePartnerLodaing(true);
      try {
        const res = await activityLogService.getPartnersAutocomplete(searchPartnerTerm);
        let newPartners = [...res.data];
        setPartnersList(newPartners);
      } catch(e) {
        console.log(e);
      } finally {
        setAutocompletePartnerLodaing(false);
      }
    }
    getPartners(debouncePartner);
  }, [debouncePartner]);

  useEffect(() => {
    const fectCampaignsList = async (searchCampaignTerm) => {
      setAutocompleteCampaignLodaing(true);
      try {
        const res = await activityLogService.getAllCampaignsAutocomplete(searchCampaignTerm);
        if(res.data) {
          let newCampaigns = [...res.data];
          setCampaignsList(newCampaigns);
        }
      } catch(e) {
        console.log(e);
      } finally {
        setAutocompleteCampaignLodaing(false);
      }
    }
    fectCampaignsList(debounceCampaign);
  }, [debounceCampaign]);

  useEffect(() => {
    setUsersLoading(true);
    const fetchUsersList = async () => {
      try {
        const res = await activityLogService.getAllUsers();
        if(res) {
          setUsersList([...res]);
        }
      } catch(e) {
        console.log(e);
      } finally {
        setUsersLoading(false);
      }
    }
    fetchUsersList();
  }, []);

  useEffect(() => {
    dispatchSelectedFilters({ type: "", payload: { filters } });
  }, []);

  const [
    {
      data: { results: activityLogs, count: totalRows },
      isFetching,
    },
    getActivityLogs,
  ] = useFetch(loadActivityLogs);

  const [tableColumns, setTableColumns] = useState([
    ...allColumns
  ]);

  useEffect(() => {
    if (selectedFilters.filters) {
      getActivityLogs();
    }
  }, [currentPage, pageSize, getActivityLogs, selectedFilters]);


  return <>
  <div className={styles.mainContainer}>
    <div className={styles.pageTitle}>
      <h4>Activity log</h4>
      <p>Follow all past activity on this page.</p>
    </div>
    <ActivityLogFilters
    campaignsList={campaignsList}
    autocompletePartnerLoading={autocompletePartnerLoading}
    autocompleteCampaignLoading={autocompleteCampaignLoading}
    usersLoading={usersLoading}
    setPartnerSearchTerm={setPartnerSearchTerm}
    setCampaignSearchTerm={setCampaignSearchTerm}
    partnersList={partnersList}
    usersList={usersList}
    filters={filters}
    setFilters={setFilters}
    submit={filters => {
      dispatchSelectedFilters({ type: "", payload: { filters } });
    }}    
    />
    <Table
    tableColumns={tableColumns}
    setTableColumns={setTableColumns}
    activityLogs={activityLogs}
    totalRows={totalRows}
    currentPage={currentPage}
    pageSize={pageSize}
    loadingActivityLogs={isFetching}
    getActivityLogs={getActivityLogs}
    onPaginationChange={(currentPage, pageSize) => {
      setCurrentPage(currentPage);
      setPageSize(pageSize);
    }}
    initialColumns={initialColumns}
    setInitialColumns={setInitialColumns}
    selectedFilters={selectedFilters}
    filters={filters}
    dispatchSelectedFilters={dispatchSelectedFilters}
    />
  </div>
  </>
}