import React, { useState, useEffect, useMemo, useReducer } from "react";
import { useFetch } from "../../utils/hooks/useFetch";
import { useDebounce } from "../../utils/hooks/useDebounce";

import { CampaignsFilters } from "./components/CampaignsFilters";
import { Table } from "./components/Table";
import { filtersReducer } from "./utils/reducers";

import { allColumns } from "./utils/columns";
import { ActionsTableCol } from "./components/ActionsTableCol";
import { campaignsService } from "../../services/campaigns";
import styles from "./Dashboard.module.css";
import { CampaignPreview } from "../../components/CampaignPreview";
import { CreativesApproval } from "./components/CreativesApproval";

export const Dashboard = () => {
  const [filters, setFilters] = useState({});
  const [selectedFilters, dispatchSelectedFilters] = useReducer(
    filtersReducer,
    {}
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [campaignsList, setCampaignsList] = useState([]);
  const [partnersList, setPartnersList] = useState([]);

  const [autocompletePartnersLoading, setAutocompletePartnersLoading] = useState(false);
  const [autocompleteCampaignsLoading, setAutocompleteCampaignsLoading] = useState(false);

  const [searchCampaign, setSearchCampaign] = useState("");
  const debounceCampaign = useDebounce(searchCampaign, 1000);

  const [searchPartner, setSearchPartner] = useState("");
  const debouncePartner = useDebounce(searchPartner, 500);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [approvalVisible, setApprovalVisible] = useState(false);
  const [campaignId, setCampaignId] = useState();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const loadCampaigns = cancelToken =>
    campaignsService.getCampaignsPaged({
      selectedFilters,
      currentPage,
      pageSize,
      cancelToken,
    });

  useEffect(() => {
    dispatchSelectedFilters({ type: "", payload: { filters } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    {
      data: { results: campaigns, count: totalRows },
      isFetching,
    },
    getCampaigns,
  ] = useFetch(loadCampaigns);


  useEffect(() => {
    const getCampaignsByNameOrID = async (searchCampaign) => {
      setAutocompleteCampaignsLoading(true);
      try {
        const res = await campaignsService.getCampaignsAutocomplete(
          searchCampaign
        );
        let newCampaigns = [...res.data];
        setCampaignsList(newCampaigns);
      } catch (e) {
        console.log(e);
      } finally {
        setAutocompleteCampaignsLoading(false);
      }
    };
    if (campaigns.length !== 0) getCampaignsByNameOrID(debounceCampaign);
  }, [debounceCampaign, campaigns]);

  useEffect(() => {
    const getPartners = async (searchPartner) => {
      setAutocompletePartnersLoading(true);
      try {
        const res = await campaignsService.getPartnersAutocomplete(
          searchPartner
        );
        let newPartners = [...res.data];
        setPartnersList(newPartners);
      } catch (e) {
        console.log(e);
      } finally {
        setAutocompletePartnersLoading(false);
      }
    };
    if (campaigns.length !== 0) getPartners(debouncePartner);
  }, [debouncePartner, campaigns]);


  const actionTableColumn = useMemo(() => {
    return {
      title: "Actions",
      key: "actions",
      fixed: "right",
      align: "center",
      render: ({ id, status, name }) => (
        <ActionsTableCol
          id={id}
          status={status}
          campaign_name={name}
          setCampaignId={setCampaignId}
          setPreviewVisible={setPreviewVisible}
          setApprovalVisible={setApprovalVisible}
          filters={filters}
        />
      ),
    };
  }, [filters]);

  useEffect(() => {
    if (selectedFilters.filters) {
      getCampaigns();
    }
  }, [currentPage, pageSize, getCampaigns, selectedFilters]);

  return <>
    <div className={styles.mainContainer}>
      <div className={styles.pageTitle}>
        <h4>Campaigns & Creatives</h4>
        <p>Have full control of the campaigns and creatives that you manage.</p>
      </div>
      <CampaignsFilters
        campaignsList={campaignsList}
        autocompletePartnersLoading={autocompletePartnersLoading}
        autocompleteCampaignsLoading={autocompleteCampaignsLoading}
        setSearchCampaign={setSearchCampaign}
        setSearchPartner={setSearchPartner}
        partnersList={partnersList}
        submit={filters => {
          dispatchSelectedFilters({ type: "", payload: { filters } });
        }}
        filters={filters}
        setFilters={setFilters}
      />
      <Table
        tableColumns={[...allColumns, actionTableColumn]}
        campaigns={campaigns}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loadingCampaigns={isFetching}
        getCampaigns={getCampaigns}
        onPaginationChange={(currentPage, pageSize) => {
          setCurrentPage(currentPage);
          setPageSize(pageSize);
        }}
        filters={filters}
        dispatchSelectedFilters={dispatchSelectedFilters}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
      <CampaignPreview campaignId={campaignId} setPreviewVisible={setPreviewVisible} previewVisible={previewVisible} />
      <CreativesApproval campaignId={campaignId} setApprovalVisible={setApprovalVisible} approvalVisible={approvalVisible} />
    </div>
  </>
};
