import { notification } from "antd";

export const openNotificationWithIcon = (
  type,
  message,
  description,
  duration = 3,
  key
) => {
  notification[type]({ message, description, duration, key });
};

export const openErrorNotification = ({ message, description, duration }) => {
  openNotificationWithIcon("error", message, description, duration);
};

export const openSuccessNotification = ({ message, description, duration }) => {
  openNotificationWithIcon("success", message, description, duration);
};
export const openWarningNotification = ({
  message,
  description,
  duration,
  key,
}) => {
  openNotificationWithIcon("warning", message, description, duration, key);
};
