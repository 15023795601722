import React, { useEffect } from "react";
import { Button, Select, Row, Col, Input } from "antd";
import { SwapOutlined } from "@ant-design/icons";
import { generatePartners } from "../utils/filterOptions";
import styles from "../Accounts.module.css";

const { Option } = Select;

export const AllCampaignsFilters = ({
  // campaignsList,
  partnersList,
  // autocompleteAllCampaignsLoading,
  autocompletePartnersLoading,
  // setSearchCampaign,
  setSearchPartner,
  filters,
  setFilters,
  submit,
}) => {

  // useEffect(() => {
  //   console.log(filters.search);
  // }, [filters.search]);

  return (
    <>
      <div className={styles.filtersContainer}>
        <h3>Filter</h3>
        <Row
          gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 8]}
          style={{ alignItems: "flex-end" }}
        >
          <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Company ID</span>
            <Select
              size="medium"
              mode="tags"
              className={styles.selectFilter}
              placeholder="All Statuses"
              allowClear
              onChange={(value) => {
                setFilters({ ...filters, company_id: value });
              }}
              value={filters.company_id}
            ></Select>
          </Col>
          {/* <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Campaign name / ID</span>
            <Select
              mode="multiple"
              onChange={(value) => setFilters({ ...filters, campaigns: value })}
              style={{ width: "100%" }}
              placeholder="Select campaigns"
              onSearch={setSearchCampaign}
              value={filters.campaigns}
              loading={autocompleteAllCampaignsLoading}
              filterOption={false}
              allowClear
            >
              {generateCampagins(campaignsList || [])}
            </Select>
          </Col> */}
          <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Account status</span>
            <Select
              size="medium"
              className={styles.selectFilter}
              placeholder="All Statuses"
              onChange={(value) => setFilters({ ...filters, is_active: value })}
              value={filters.is_active}
              allowClear={true}
            >
              <Option value={1}>Active</Option>
              <Option value={0}>Suspended</Option>
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Partner name / Company</span>
            <Select
              mode="multiple"
              onChange={(value) =>
                setFilters({ ...filters, partner_id: value })
              }
              style={{ width: "100%" }}
              placeholder="Select partners/companies"
              onSearch={setSearchPartner}
              value={filters.partner_id}
              loading={autocompletePartnersLoading}
              filterOption={false}
              allowClear
            >
              {generatePartners(partnersList || [])}
            </Select>
          </Col>
          {/* <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Partner name / Company</span>
            <Select
              mode="multiple"
              onChange={(value) =>
                setFilters({ ...filters, partner_id: value })
              }
              style={{ width: "100%" }}
              placeholder="Select partners/companies"
              onSearch={setSearchPartner}
              value={filters.partner_id}
              loading={autocompletePartnersLoading}
              filterOption={false}
              allowClear
            >
              {generatePartners(partnersList || [])}
            </Select>
          </Col> */}
          <Col xs={24} sm={12} md={8} lg={6} xxl={5}>
            <span className={styles.filterSpan}>Search</span>
            <Input
              onChange={(e) =>
                setFilters({ ...filters, search: e.target.value })
              }
              style={{ width: "100%" }}
              placeholder="Search by keyword"
              value={filters.search}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Button
              className={styles.updateButton}
              type="primary"
              size="medium"
              onClick={() => submit(filters)}
              icon={<SwapOutlined />}
            >
              Update
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};
