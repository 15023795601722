import React from "react";
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { campaignsService } from "../../../services/campaigns";

export const updateCampaigns = async (ids, action, filters, dispatchSelectedFilters, setSelectedRowKeys) => {
    const reducedCampaigns = ids.reduce((parsed, id) => {
        parsed.campaigns.push({ id: id, status: action })
        return parsed
    }, { campaigns: [] })

    notification.open({
        message: "Updating...",
        key: "update",
        icon: <LoadingOutlined style={{ color: "#00cc66" }} />,
        duration: 0,
    });

    try {
        const res = await campaignsService.updateCampaign(reducedCampaigns);
        if (res.success) {
            notification["success"]({
                message: "Update successful!",
                key: "update",
            });
            setSelectedRowKeys([]);
            dispatchSelectedFilters({ type: "", payload: { filters } });
        } else {
            throw new Error();
        }
    } catch (e) {
        notification["error"]({
            message: "Error, failed to update!",
            key: "update",
        });
    }
};
