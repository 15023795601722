import React from "react";

import { Button, Space, Tooltip } from "antd";
import { updateCampaigns } from "../utils";

import { EyeOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";

import styles from "../AllCampaigns.module.css";

export const ActionsTableCol = ({ id, status, filters, setCampaignId, setPreviewVisible, dispatchSelectedFilters, setSelectedRowKeys }) => {

  const openPreview = () => {
    setCampaignId(id);
    setPreviewVisible(true);
  }

  return <>
    <Space>
      <>
        <Tooltip title="Preview">
          <Button onClick={openPreview} style={{ color: "#737373" }} icon={<EyeOutlined />} />
        </Tooltip>
        {
          status === 'suspended' ?
            <Tooltip title="Approve">
              <Button disabled={status !== 'active' && status !== "suspended"} onClick={() => updateCampaigns([id], 'active', filters, dispatchSelectedFilters, setSelectedRowKeys)} className={styles.approve} icon={<Icon component={() => (<img className={styles.activateCampaignIcon} src="/images/activate.png" alt="" />)} />} />
            </Tooltip>
            :
            ((status === 'active') ?
              <Tooltip title="Suspend">
                <Button disabled={status !== 'active' && status !== "suspended"} onClick={() => updateCampaigns([id], 'suspended', filters, dispatchSelectedFilters, setSelectedRowKeys)} className={styles.suspend} icon={<Icon component={() => (<img className={styles.suspendCampaignIcon} src="/images/suspend.png" alt="" />)} />} />
              </Tooltip>
              :
              <Tooltip title="Approve">
                <Button disabled={true} icon={<Icon component={() => (<img className={styles.activateCampaignIcon} src="/images/activate.png" alt="" />)} />} />
              </Tooltip>)
        }
      </>
    </Space>
  </>
};
